.portfolio-page {
    padding: 70px 0;
    .portfolio-page__header {
        text-align: center;
        .name {
            font-family: $font-open-sans;
            font-weight: 700;
            font-size: 64px;
            color: $font-color-white;
            text-transform: uppercase;
            @media (max-width: 991px) {
                font-size: 50px; }
            @media (max-width: 767px) {
                font-size: 40px; }
            @media (max-width: 575px) {
                font-size: 30px; }
            &.name__less {
                font-size: 50px;
                @media (max-width: 991px) {
                    font-size: 40px; }
                @media (max-width: 767px) {
                    font-size: 32px; }
                @media (max-width: 575px) {
                    font-size: 26px; } } }
        .subname {
            font-family: $font-open-sans;
            font-weight: 400;
            font-size: 26px;
            color: $font-color-white;
            @media (max-width: 991px) {
                font-size: 24px; }
            @media (max-width: 767px) {
                font-size: 20px; }
            @media (max-width: 575px) {
                font-size: 18px; }
            a {
                color: $font-color-white;
                text-decoration: underline; } } }
    .item-header {
        font-family: $font-open-sans;
        font-size: 36px;
        color: $font-color-white;
        padding-bottom: 20px;
        @media (max-width: 767px) {
            font-size: 26px; }
        &.item-header__size_less {
            font-size: 26px;
            @media (max-width: 767px) {
                font-size: 20px; } } }

    .text {
        font-family: $font-roboto;
        font-size: 16px;
        line-height: 150%;
        color: $font-color-white; }
    .project-photo {
        filter: drop-shadow(0 0 18px rgba(0, 0, 0, .5));
        // box-shadow: 0 0 18px rgba(0, 0, 0, .5)
        border-radius: 8px;
        &.project-photo__top-radius {
            border-radius: 0;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.15)); }
        &.project-photo__no-radius {
            border-radius: 0;
            filter: none; }
        &.project-photo__bottom-radius {
            border-radius: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            filter: drop-shadow(0 30px 10px rgba(0, 0, 0, 0.15)); } }
    .work-count-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @media (max-width: 991px) {
            padding-bottom: 20px; }
        .count {
            flex: 0 0 auto;
            font-family: $font-roboto;
            font-weight: 900;
            font-size: 144px;
            line-height: 144px;
            color: $font-color-white;
            padding-right: 10px;
            opacity: 0.35;
            @media (max-width: 991px) {
                font-size: 80px;
                line-height: 80px; }
            @media (max-width: 767px) {
                font-size: 60px;
                line-height: 60px; } }
        .text {
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%; } }
    .photo-descriptions {
        padding-right: 33%;
        position: relative;
        @media (max-width: 991px) {
            width: 100%;
            padding-right: 0; }
        .desc {
            width: 30%;
            position: absolute;
            right: 0;
            @media (max-width: 991px) {
                position: static;
                width: 100%; } }
        .logo-big-vertical {
            opacity: .7;
            position: absolute;
            left: -110px;
            top: 10%;
            z-index: -1;
            @media (max-width: 767px) {
                display: none; } } }
    .photo-descriptions-by-rows {
        position: relative;
        .photo-descriptions-by-rows__row {
            position: relative;
            img {
                width: 66%;
                @media (max-width: 991px) {
                    width: 100%; } }
            .desc {
                width: 30%;
                position: absolute;
                right: 0;
                top: 0;
                @media (max-width: 991px) {
                    display: none; } } }
        .desc-mobile {
            display: none;
            width: 100%;
            position: static;
            margin-top: 20px;
            @media (max-width: 991px) {
                display: block; } }
        .logo-big-vertical {
            opacity: .7;
            position: absolute;
            left: -110px;
            top: 10%;
            z-index: -1;
            @media (max-width: 767px) {
                display: none; } } }



    .results {
        .result__content {
            text-align: center;
            .value-line {
                padding-bottom: 10px;
                .pre {
                    font-family: $font-roboto;
                    font-weight: 400;
                    font-size: 36px;
                    color: $font-color-white;
                    padding-right: 5px; }
                .value {
                    font-family: $font-roboto;
                    font-weight: 900;
                    font-size: 64px;
                    line-height: 64px;
                    color: $font-color-white; } } } }
    img {
        max-width: 100%; }

    // Colors
    // Ellow content theme
    &.portfolio-page__color_yellow_1 {
        .portfolio-page__header {
            .name {
                color: #FFD763; } }
        .item-header {
            color: #FFD763; }
        .work-count-item {
            .count {
                color: #FFD763; } } }
    &.portfolio-page__color_green_2 {
        .portfolio-page__header {
            .name {
                color: #18BA9A; }
            .subname a {
                color: #42D273; } }
        .item-header {
            color: #18BA9A; }
        .work-count-item {
            .count {
                color: #18BA9A; } }
        .arrow-list-items {
            .arrow-list-items__item {
                .icon svg path {
                    fill: #18BA9A; } } }
        .count-list-items {
            .count-list-items__item {
                .count {
                    color: #18BA9A; } } } }

    .reviews-page {
        .text {
            color: #212121; } } }





