.btn {
    border-radius: 0;
    @include transition(200ms);
    &:focus {
        outline: none;
        box-shadow: none; }
    // Types
    &.btn__light {
        font-family: $font-roboto;
        border: 2px solid $primary-color-orange;
        color: $font-color-orange;
        box-sizing: border-box;
        background: none;
        letter-spacing: 0.1em;
        &:hover {
            background: $primary-color-orange;
            color: $font-color-black; }
        &:active {
            background: $primary-color-orange-darker;
            border-color: $primary-color-orange-darker;
            color: $font-color-black; } }
    &.btn__light-purpure {
        font-family: $font-roboto;
        border: 2px solid $primary-color-block-purpure;
        color: $primary-color-block-purpure;
        box-sizing: border-box;
        background: none;
        letter-spacing: 0.1em;
        &:hover {
            background: $primary-color-block-purpure;
            color: $font-color-white; }
        &:active {
            background: $primary-color-block-purpure;
            border-color: $primary-color-block-purpure;
            color: $font-color-white; } }
    &.btn__accent {
        font-family: $font-roboto;
        border: 2px solid $primary-color-orange;
        color: $font-color-black;
        box-sizing: border-box;
        background: $primary-color-orange;
        letter-spacing: 0.1em;
        font-weight: 700;
        &:hover {
            background: $primary-color-orange-lighter;
            border-color: $primary-color-orange-lighter;
            color: $font-color-black; }
        &:active {
            background: $primary-color-orange-darker;
            border-color: $primary-color-orange-darker;
            color: $font-color-black; }
        // &.btn__accent_type-color-2
        //     border: 2px solid #FF6A6A
 }        //     background: #FF6A6A
    &.btn__accent-purpure-pale {
        font-family: $font-roboto;
        border: 2px solid #FF6A6A;
        color: $font-color-black;
        box-sizing: border-box;
        background: #FF6A6A;
        letter-spacing: 0.1em;
        font-weight: 700;
        &:hover {
            background: #D77272;
            border-color: #D77272;
            color: $font-color-black; }
        &:active {
            background: #D77272;
            border-color: #D77272;
            color: $font-color-black; } }
    &.btn__dark-light {
        font-family: $font-roboto;
        border: 2px solid $primary-color-dark;
        color: $font-color-white;
        box-sizing: border-box;
        background: none;
        letter-spacing: 0.1em;
        font-weight: 400;
        &::after {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAzOCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2Ljc4MjIgOC4yMDcxMUMzNy4xNzI3IDcuODE2NTggMzcuMTcyNyA3LjE4MzQyIDM2Ljc4MjIgNi43OTI4OUwzMC40MTgyIDAuNDI4OTMyQzMwLjAyNzcgMC4wMzg0MDc4IDI5LjM5NDUgMC4wMzg0MDc4IDI5LjAwNCAwLjQyODkzMkMyOC42MTM1IDAuODE5NDU3IDI4LjYxMzUgMS40NTI2MiAyOS4wMDQgMS44NDMxNUwzNC42NjA5IDcuNUwyOS4wMDQgMTMuMTU2OUMyOC42MTM1IDEzLjU0NzQgMjguNjEzNSAxNC4xODA1IDI5LjAwNCAxNC41NzExQzI5LjM5NDUgMTQuOTYxNiAzMC4wMjc3IDE0Ljk2MTYgMzAuNDE4MiAxNC41NzExTDM2Ljc4MjIgOC4yMDcxMVpNMC4zNTU0NjkgOC41SDM2LjA3NTFWNi41SDAuMzU1NDY5VjguNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
            transform: translateX(0);
            display: inline-block;
            padding-left: 10px;
            position: relative;
            top: 1px;
            @include transition(200ms); }
        &:hover {
            background: $primary-color-dark;
            &::after {
                transform: translateX(10px); } }
        &:active {
            background: $primary-color-dark;
            &::after {
                transform: translateX(12px); } }
        &.btn__dark-light_two-line-mobile {
            @media (max-width: 575px) {
                &::after {
                    display: block; } } } }
    &.btn__show-hide {
        border: 2px solid $primary-color-white;
        box-sizing: border-box;
        background: none;
        &::after {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAzMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMS40Mzg0OEwxNSAxNS40Mzg1TDI5IDEuNDM4NDgiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K');
            width: 30px;
            transform: translateY(0);
            display: inline-block;
            position: relative;
            top: 2px;
            @include transition(200ms); }
        &:hover {
            background: $primary-color-white;
            &::after {
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAzMSAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNSAxTDE1LjUgMTVMMjkuNSAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
                width: 30px;
                transform: translateY(10px); } }
        &:active {
            background: $primary-color-white;
            &::after {
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAzMSAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNSAxTDE1LjUgMTVMMjkuNSAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
                width: 30px;
                transform: translateY(12px); } }
        &.btn__show-hide_rotate {
            &::after {
                top: -2px;
                transform: translateY(0) rotate(180deg); }
            &:hover {
                &::after {
                    transform: translateY(-10px) rotate(180deg); } }
            &:active {
                &::after {
                    transform: translateY(-12px) rotate(180deg); } } } }
    &.btn__red {
        font-family: $font-roboto;
        border: 2px solid $primary-color-red;
        color: $font-color-white;
        box-sizing: border-box;
        background: $primary-color-red;
        letter-spacing: 0.1em;
        &:hover {
            background: $primary-color-red-hover;
            border-color: $primary-color-red-hover; }
        &:active {
            background: $primary-color-red-active;
            border-color: $primary-color-red-hover; }
        &.btn__red_arrow {
            &::after {
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAzOCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2Ljc4MjIgOC4yMDcxMUMzNy4xNzI3IDcuODE2NTggMzcuMTcyNyA3LjE4MzQyIDM2Ljc4MjIgNi43OTI4OUwzMC40MTgyIDAuNDI4OTMyQzMwLjAyNzcgMC4wMzg0MDc4IDI5LjM5NDUgMC4wMzg0MDc4IDI5LjAwNCAwLjQyODkzMkMyOC42MTM1IDAuODE5NDU3IDI4LjYxMzUgMS40NTI2MiAyOS4wMDQgMS44NDMxNUwzNC42NjA5IDcuNUwyOS4wMDQgMTMuMTU2OUMyOC42MTM1IDEzLjU0NzQgMjguNjEzNSAxNC4xODA1IDI5LjAwNCAxNC41NzExQzI5LjM5NDUgMTQuOTYxNiAzMC4wMjc3IDE0Ljk2MTYgMzAuNDE4MiAxNC41NzExTDM2Ljc4MjIgOC4yMDcxMVpNMC4zNTU0NjkgOC41SDM2LjA3NTFWNi41SDAuMzU1NDY5VjguNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
                transform: translateX(0);
                display: inline-block;
                padding-left: 10px;
                position: relative;
                top: 1px;
                @include transition(200ms); }
            &:hover {
                &::after {
                    transform: translateX(10px); } }
            &:active {
                &::after {
                    transform: translateX(12px); } } } }
    &.btn-hamburger {
        padding: 0; }

    // Sizes
    &.btn__size_large {
        padding: 12px 33px;
        text-transform: uppercase;
        font-size: 18px; }
    &.btn__size_large-width-100 {
        padding: 10px 10px;
        text-transform: uppercase;
        font-size: 18px;
        width: 100%; }
    &.btn__w-100 {
        width: 100%; }
    &.btn__lg_w-100 {
        @media (max-width: 767px) {
            width: 100%; } } }


// body dark modifi
body.dark {
    .btn {
        &.btn__dark-light {
            border-color: $primary-color-white;
            &:hover, &:active {
                background: $primary-color-white;
                color: $font-color-black;
                &::after {
                    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM3LjIwOCA4LjM3MTY2QzM3LjU5ODUgNy45ODExMyAzNy41OTg1IDcuMzQ3OTcgMzcuMjA4IDYuOTU3NDRMMzAuODQ0IDAuNTkzNDgzQzMwLjQ1MzUgMC4yMDI5NTkgMjkuODIwMyAwLjIwMjk1OSAyOS40Mjk4IDAuNTkzNDgzQzI5LjAzOTMgMC45ODQwMDcgMjkuMDM5MyAxLjYxNzE3IDI5LjQyOTggMi4wMDc3TDM1LjA4NjYgNy42NjQ1NUwyOS40Mjk4IDEzLjMyMTRDMjkuMDM5MyAxMy43MTE5IDI5LjAzOTMgMTQuMzQ1MSAyOS40Mjk4IDE0LjczNTZDMjkuODIwMyAxNS4xMjYxIDMwLjQ1MzUgMTUuMTI2MSAzMC44NDQgMTQuNzM1NkwzNy4yMDggOC4zNzE2NlpNMC43ODEyNSA4LjY2NDU1SDM2LjUwMDhWNi42NjQ1NUgwLjc4MTI1VjguNjY0NTVaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K'); } } } } }


