@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Roboto:wght@100;300;400;700;900&display=swap&family=Roboto+Condensed:wght@400&display=swap");

$font-open-sans: 'Open Sans', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-roboto-condensed: 'Roboto Condensed', sans-serif;

// [
//     'aqua-blue', 'purpure', 'orange-dirty', 'dark-blue', 'green-natural', 'green', 'yellow',
//     'yellow-grey', 'orange', 'green-lighter', 'orange-bright'
// ]

$primary-color-white: #ffffff;
$primary-color-black: #000000;
$primary-color-orange: #FF8A01;
$primary-color-dark: #11292B;
$primary-color-red: #B6380A;
$primary-color-red-hover: #F3561D;
$primary-color-red-active: #9F3109;
$primary-color-orange-darker: #E07900;
$primary-color-orange-lighter: #FFA740;
$primary-color-block-aqua-blue: #44A5AC;
$primary-color-block-purpure: #8A3A6A;
$primary-color-block-orange-dirty: #ED723E;
$primary-color-block-dark-blue: #324C68;
$primary-color-block-green: #3D9B03;
$primary-color-block-green-natural: #20AC79;
$primary-color-block-yellow: #D9B519;
$primary-color-block-orange: #FF8A01;
$primary-color-block-green-lighter: #20AC7A;
$primary-color-block-yellow-grey: #B6997B;
$primary-color-block-orange-bright: #F24B0D;
$primary-color-block-dark: #131313;
$primary-color-block-light: #F2F2F2;

$primary-color-bg-dark: #212121;

$font-color-white: #ffffff;
$font-color-black: #000000;
$font-color-white-opacity: rgba(255, 255, 255, 0.6);
$font-color-orange: #FF8A01;
$font-color-red: #FF4040;

@mixin transition($sec) {
  -webkit-transition: $sec linear;
  -moz-transition: $sec linear;
  transition: $sec linear; }
@mixin rotate($deg) {
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg); }

body {
  scrollbar-color: #6D6D6D #151515;
  scrollbar-width: 16px;
  &::-webkit-scrollbar {
      width: 16px; }
  &::-webkit-scrollbar-track {
      background: #151515; }
  &::-webkit-scrollbar-thumb {
      background: #6D6D6D;
      @include transition(200ms); }
  &::-webkit-scrollbar-thumb:hover {
      background: #444343; }
  &.dark {
    background: $primary-color-bg-dark; } }

.scrolltop {
  position: fixed;
  right: 17px;
  bottom: 80px;
  z-index: 1001;
  cursor: pointer;
  @include transition(200ms);
  opacity: 0; }

.mt-90 {
  margin-top: 90px; }
.mt-90-more-md {
  margin-top: 90px;
  @media (max-width: 767px) {
    margin-top: auto; } }
.mt-md-90 {
  @media (max-width: 767px) {
    margin-top: 90px; } }

.slider-outer-wrapper {
  position: relative;
  @media (max-width: 767px) {
      text-align: center; }
  .swiper-button-prev {
    left: -36px;
    @media (max-width: 1256px) {
        left: -9px; }
    @media (max-width: 1030px) {
        display: none; }
    @media (max-width: 991px) {
        display: flex;
        left: -9px; }
    @media (max-width: 767px) {
        padding-right: 10px; } }
  .swiper-button-next {
      right: -36px;
      @media (max-width: 1256px) {
          right: -9px; }
      @media (max-width: 1030px) {
          display: none; }
      @media (max-width: 991px) {
          right: -9px;
          display: flex; }
      @media (max-width: 767px) {
          padding-left: 10px; } }
  .swiper-button-next, .swiper-button-prev {
      @media (max-width: 767px) {
          display: inline-flex;
          position: static;
          width: auto;
          height: auto;
          padding-top: 35px; } } }
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  display: none; }
