.questions-accordion {
    .card {
        background: none;
        border: none;
        .card-header {
            background: none;
            border: none;
            padding: 20px 0;
            border-top: 1px solid $primary-color-white;
            cursor: pointer;
            h2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center; }
            .btn-name {
                font-family: $font-roboto;
                font-size: 24px;
                color: $font-color-white;
                padding: 0;
                box-shadow: none !important;
                text-decoration: none !important;
                display: inline-block;
                width: auto;
                max-width: 85%; }
            .dropdown-icon {
                flex: 0 0 auto;
                max-width: 100%;
                display: inline-block;
                height: 43px;
                &::after {
                    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNSAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeTE9IjE3LjEyNyIgeDI9IjM1IiB5Mj0iMTcuMTI3IiBzdHJva2U9IndoaXRlIi8+CjxsaW5lIHgxPSIxNyIgeTE9IjM1LjEyNyIgeDI9IjE3IiB5Mj0iMC4xMjY5NTMiIHN0cm9rZT0id2hpdGUiLz4KPC9zdmc+Cg==');
                    position: relative;
                    top: 0; } } }
        .card-body {
            padding: 0;
            font-family: $font-open-sans;
            font-weight: 300;
            font-size: 18px;
            line-height: 25px;
            color: $font-color-white;
            padding-bottom: 30px;
            padding-left: 0.15em; }
        &.active {
            .card-header {
                .btn-name {
                    color: #230F1B; }
                .dropdown-icon {
                    &::after {
                        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDM1IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHkxPSIwLjYyNjk1MyIgeDI9IjM1IiB5Mj0iMC42MjY5NTMiIHN0cm9rZT0id2hpdGUiLz4KPC9zdmc+Cg==');
                        top: -12px; } } } }
        &:last-child {
            border-bottom: 1px solid $primary-color-white; } } }


