.slide-block__contacts-texts {
    @media (max-width: 767px) {
        padding-bottom: 30px; }
    .tel, .mail {
        font-family: $font-open-sans;
        font-weight: 600;
        color: $font-color-white;
        font-size: 36px;
        line-height: 42px;
        display: block;
        @media (max-width: 1199px) {
            font-size: 34px;
            line-height: 36px; }
        @media (max-width: 991px) {
            font-size: 24px;
            line-height: 26px; } }
    .address, .work-times {
        font-family: $font-open-sans;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #3D1303; } }
