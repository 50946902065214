.slide-block-overlay {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    &::before {
        content: '';
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
    &.slide-block-overlay__aqua-blue {
        &::before {
            display: block;
            background: $primary-color-block-aqua-blue;
            opacity: .85; } }
    &.slide-block-overlay__purpure {
        &::before {
            display: block;
            background: $primary-color-block-purpure;
            opacity: .85; } }
    &.slide-block-overlay__orange-dirty {
        &::before {
            display: block;
            background: $primary-color-block-orange-dirty;
            opacity: .85; } }
    &.slide-block-overlay__dark-blue {
        &::before {
            display: block;
            background: $primary-color-block-dark-blue;
            opacity: .85; } }
    &.slide-block-overlay__green-natural {
        &::before {
            display: block;
            background: $primary-color-block-green-natural;
            opacity: .85; } }
    &.slide-block-overlay__green {
        &::before {
            display: block;
            background: $primary-color-block-green;
            opacity: .85; } }
    &.slide-block-overlay__yellow {
        &::before {
            display: block;
            background: $primary-color-block-yellow;
            opacity: .85; } }
    &.slide-block-overlay__yellow-grey {
        &::before {
            display: block;
            background: $primary-color-block-yellow-grey;
            opacity: .85; } }
    &.slide-block-overlay__orange {
        &::before {
            display: block;
            background: $primary-color-block-orange;
            opacity: .85; } }
    &.slide-block-overlay__green-lighter {
        &::before {
            display: block;
            background: $primary-color-block-green-lighter;
            opacity: .85; } }
    &.slide-block-overlay__orange-bright {
        &::before {
            display: block;
            background: $primary-color-block-orange-bright;
            opacity: .85; } }
    &.slide-block-overlay__light {
        &::before {
            display: block;
            background: $primary-color-white;
            opacity: .85; } } }
