header.header {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    align-content: space-between;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &::before {
        content: '';
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
    .header__top-bar {
        flex: 0 0 100%;
        max-width: 100%; }
    .header__offers {
        flex: 0 0 100%;
        max-width: 100%;
        @media (max-width: 767px) {
            padding: 30px 0; } }
    .header__bottom-features {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20vh;
        @media (max-width: 1199px) {
            margin-bottom: 14vh; }
        @media (max-width: 767px) {
            margin-bottom: 20px;
            button {
                margin-bottom: 20px; } } }

    &.header__height_auto {
        min-height: auto;
        .header__offers {
            padding-top: 80px;
            padding-bottom: 90px;
            @media (max-width: 767px) {
                padding: 32px 0; } } }


    // Colors
    &.header__overlay_aqua-blue {
        &::before {
            display: block;
            background: $primary-color-block-aqua-blue;
            opacity: .8; } }
    &.header__overlay_purpure {
        &::before {
            display: block;
            background: $primary-color-block-purpure;
            opacity: .8; } }
    &.header__overlay_orange-dirty {
        &::before {
            display: block;
            background: $primary-color-block-orange-dirty;
            opacity: .8; } }
    &.header__overlay_dark-blue {
        &::before {
            display: block;
            background: $primary-color-block-dark-blue;
            opacity: .8; } }
    &.header__overlay_green-natural {
        &::before {
            display: block;
            background: $primary-color-block-green-natural;
            opacity: .8; } }
    &.header__overlay_green {
        &::before {
            display: block;
            background: $primary-color-block-green;
            opacity: .8; } }
    &.header__overlay_yellow {
        &::before {
            display: block;
            background: $primary-color-block-yellow;
            opacity: .8; } }
    &.header__overlay_yellow-grey {
        &::before {
            display: block;
            background: $primary-color-block-yellow-grey;
            opacity: .8; } }
    &.header__overlay_orange {
        &::before {
            display: block;
            background: $primary-color-block-orange;
            opacity: .8; } }
    &.header__overlay_green-lighter {
        &::before {
            display: block;
            background: $primary-color-block-green-lighter;
            opacity: .8; } }
    &.header__overlay_orange-bright {
        &::before {
            display: block;
            background: $primary-color-block-orange-bright;
            opacity: .8; } }
    &.header__overlay_light {
        &::before {
            display: block;
            background: $primary-color-white;
            opacity: .8; } }
    &.header__color_dark {
        .top-bar {
            .top-bar__nav .nav .nav-item {
                .nav-link {
                    color: $font-color-black; } }
            .top-bar__contacts {
                .tel a {
                    color: $font-color-black; } } } } }

// Fake img
.fake-img {
    height: 0px;
    overflow: hidden; }

// Call fixed Button
.call-fixed {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 1001;
    cursor: pointer;
    path {
        fill: $primary-color-white; }
    circle {
        stroke: $primary-color-white; }
    &.call-fixed__color_aqua-blue {
        path {
            fill: $primary-color-block-aqua-blue; }
        circle {
            stroke: $primary-color-block-aqua-blue; } }
    &.call-fixed__color_purpure {
        path {
            fill: $primary-color-block-purpure; }
        circle {
            stroke: $primary-color-block-purpure; } }
    &.call-fixed__color_orange-dirty {
        path {
            fill: $primary-color-block-orange-dirty; }
        circle {
            stroke: $primary-color-block-orange-dirty; } }
    &.call-fixed__color_dark-blue {
        path {
            fill: $primary-color-block-dark-blue; }
        circle {
            stroke: $primary-color-block-dark-blue; } }
    &.call-fixed__color_green-natural {
        path {
            fill: $primary-color-block-green-natural; }
        circle {
            stroke: $primary-color-block-green-natural; } }
    &.call-fixed__color_green {
        path {
            fill: $primary-color-block-green; }
        circle {
            stroke: $primary-color-block-green; } }
    &.call-fixed__color_yellow {
        path {
            fill: $primary-color-block-yellow; }
        circle {
            stroke: $primary-color-block-yellow; } }
    &.call-fixed__color_yellow-grey {
        path {
            fill: $primary-color-block-yellow-grey; }
        circle {
            stroke: $primary-color-block-yellow-grey; } }
    &.call-fixed__color_orange {
        path {
            fill: $primary-color-block-orange; }
        circle {
            stroke: $primary-color-block-orange; } }
    &.call-fixed__color_green-lighter {
        path {
            fill: $primary-color-block-green-lighter; }
        circle {
            stroke: $primary-color-block-green-lighter; } }
    &.call-fixed__color_orange-bright {
        path {
            fill: $primary-color-block-orange-bright; }
        circle {
            stroke: $primary-color-block-orange-bright; } } }
