.form-profi {
    .form-group {
        flex-grow: 1;
        position: relative;
        @media (max-width: 575px) {
            margin-bottom: 0; }
        input {
            width: 100%; } }
    input, textarea {
        border: 2px solid $primary-color-red;
        box-sizing: border-box;
        background: none;
        border-radius: 0;
        color: $font-color-white;
        font-family: $font-roboto;
        font-weight: 400;
        font-size: 18px;
        padding: 25.5px 13px;
        &::placeholder {
            color: $font-color-white; }
        &:focus {
            background: none;
            border: 2px solid $primary-color-red;
            box-shadow: none;
            color: $font-color-white; }
        &.br-none {
            border-right: none; }
        &.br-less-md {
            @media (max-width: 767px) {
                border-right: 2px solid $primary-color-red; } } }
    textarea {
        padding: 12px 13px; }
    .custom-input {
        position: relative;
        &__file {
            width: 100%;
            opacity: 0;
            visibility: hidden;
            position: absolute; }
        &__file-icon-wrapper {
            display: flex;
            align-items: center; }
        &__file-button-text {
            padding-left: 10px; }
        &__file-button {
            font-family: $font-roboto;
            color: $primary-color-black;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer; } }
    .form-group {
        @media (max-width: 575px) {
            width: 100%;
            margin-bottom: 10px; } }
    .form-check {
        position: relative;
        display: inline-block;
        input.form-check-input {
            opacity: 0; }
        label.form-check-label {
            font-family: $font-roboto;
            font-size: 20px;
            color: #562442;
            padding-left: 10px; }
        input.form-check-input:checked {
 }            //
        input.form-check-input + label.form-check-label {
            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                top: 2px;
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNy41IiBjeT0iNy42MjE1OCIgcj0iNyIgZmlsbD0id2hpdGUiIHN0cm9rZT0iI0MzQjRCRCIvPgo8L3N2Zz4K');
                @include transition(200ms); } }
        input.form-check-input:checked + label.form-check-label {
            &::before {
               content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNy41IiBjeT0iNy42MjE1OCIgcj0iNyIgZmlsbD0id2hpdGUiIHN0cm9rZT0iI0MzQjRCRCIvPgo8Y2lyY2xlIGN4PSI3LjQ5OTY3IiBjeT0iNy42MjE3NCIgcj0iMy45NzIzMiIgZmlsbD0iIzhBM0E2QSIvPgo8L3N2Zz4K'); } } }


    .btn__red {
        font-size: 18px;
        text-transform: uppercase;
        padding: 12px 13px;
        // padding: 12px 33px
        flex-grow: 1; }
    p.policy, p.policy a {
        font-family: $font-roboto;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: $font-color-white;
        padding-top: 10px;
        span, a {
            text-decoration: underline; } }
    .error-line {
        position: absolute;
        left: 0;
        top: -16px;
        font-family: $font-roboto;
        font-size: 12px;
        line-height: 14px;
        color: $font-color-red;
        display: none;
        @include transition(200ms);
        transform: translateY(20px); }
    &.form-profi__error {
        input[required].form-profi__item_error, input[placeholder=Телефон].form-profi__item_error {
            color: $font-color-red !important;
            border-color: $font-color-red !important; }
        .error-line {
            display: inline;
            transform: translateY(0); } }
    &.form-profi_color_purpure {
        input, textarea {
            border-color: #562442;
            &:focus {
                border-color: #562442; } }
        .btn__red {
            border-color: #562442;
            background: #562442; } }
    &.form-profi_color_light {
        input, textarea {
            border-color: $primary-color-bg-dark;
            color: $primary-color-bg-dark;
            background: $primary-color-white;
            &:focus {
                border-color: $primary-color-bg-dark; }
            &::placeholder {
                color: $primary-color-bg-dark; } }
        .btn__red {
            color: $font-color-white;
            border-color: $primary-color-orange;
            background: $primary-color-orange;
            &:hover {
                background: $primary-color-orange-lighter;
                border-color: $primary-color-orange-lighter;
                color: $font-color-black; }
            &:active {
                background: $primary-color-orange-darker;
                border-color: $primary-color-orange-darker;
                color: $font-color-black; } }
        p.policy, p.policy a {
            color: #737373; } }
    &.form-profi__mw-85 {
        max-width: 85%;
        @media (max-width: 575px) {
            max-width: 100%; } }
    &.form-profi__mw-lg-85 {
        max-width: 85%;
        @media (max-width: 991px) {
            max-width: 100%; } }
    &.form-profi__mobile-less-md {
        .form-group {
            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px; } } }
    &.form-profi__mobile-margings-less-md {
        input, textarea {
            margin-bottom: 16px; } } }

// Fix red background for error styles
.slide-block_color_orange-bright {
    .form-profi {
        &.form-profi__error {
            input[required].form-profi__item_error, input[placeholder=Телефон].form-profi__item_error {
                color: $font-color-white !important;
                border-color: $font-color-white !important; }
            .error-line {
                color: $font-color-white !important; } } } }


.form-profi__mw-85 {
    max-width: 85%;
    @media (max-width: 575px) {
        max-width: 100%; } }
