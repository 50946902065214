.block-header {
    font-family: $font-open-sans;
    font-weight: 300;
    font-size: 68px;
    line-height: 68px;
    color: $font-color-black;
    position: relative;
    @media (max-width: 991px) {
        font-size: 60px;
        line-height: 60px; }
    @media (max-width: 555px) {
        font-size: 40px;
        line-height: 40px; }
    .color {
        font-family: $font-roboto;
        font-weight: 900;
        font-size: 185px;
        color: rgba(0, 0, 0, 0.14);
        position: absolute;
        left: -70px;
        top: 0;
        @media (max-width: 1199px) {
            left: -47px; }
        @media (max-width: 601px) {
            left: -20px; }
        @media (max-width: 555px) {
            font-size: 100px;
            left: -20px;
            top: 3px; } } }
