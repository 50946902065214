.bottom-features {
    .bottom-features__item {
        display: flex;
        @media (max-width: 767px) {
            align-items: center;
            margin-bottom: 20px; }
        .icon {
            flex: 0 0 25%;
            max-width: 25%;
            padding-right: 15px;
            img {
                max-width: 100%; } }
        .text {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            font-family: $font-roboto;
            font-size: 18px;
            line-height: 150%;
            color: $font-color-white;
            font-weight: 300;
            @media (max-width: 991px) {
                font-size: 14px; } } }
    .tel {
        padding-bottom: 20px;
        a {
            font-family: $font-open-sans;
            font-weight: 600;
            font-size: 28px;
            line-height: 28px;
            color: $font-color-white;
            &:hover {
                text-decoration: none; } } }
    &.bottom-features__mobile {
        padding: 20px 0;
        background: $primary-color-black; } }


