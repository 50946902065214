.reviews {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    text-align: left;
    .reviews__card {
        flex: 0 0 33%;
        max-width: 33%;
        position: relative;
        .card-wrapper {
            padding: 20px;
            height: 100%;
            .card-content {
                height: 100%;
                background: $primary-color-white;
                padding: 40px 15px;
                clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
                .text {
                    font-family: $font-roboto;
                    font-size: 16px;
                    line-height: 150%;
                    color: #230F1B;
                    padding-bottom: 30px; }
                .between {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center; }
                .name {
                    flex: 0 0 auto;
                    flex-wrap: wrap;
                    font-family: $font-roboto;
                    font-size: 16px;
                    line-height: 150%;
                    color: #230F1B; }
                .date {
                    flex: 0 0 auto;
                    flex-wrap: wrap;
                    font-family: $font-roboto;
                    font-size: 14px;
                    line-height: 150%;
                    color: #6A6A6A; }
                .company {
                    font-family: $font-roboto;
                    font-size: 14px;
                    line-height: 150%;
                    color: #6A6A6A; }
                .card-content-flex {
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    .top, .bottom {
                        flex: 0 0 100%;
                        max-width: 100%; }
                    .bottom {
                        align-self: flex-end; } } } }
        &::after {
            content: '';
            position: absolute;
            left: calc(20px + 18px);
            top: calc(20px + 18px);
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            background: #00000066;
            z-index: -1;
            clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%); } }
    &::before, &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA2NCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMjkuNzQxOEgxMy43MTQzTDQuNTcxMzUgNDkuNTY5NkgxOC4yODU2TDI3LjQyODUgMjkuNzQxOFYwSDBWMjkuNzQxOFoiIGZpbGw9IiM1NjI0NDIiIGZpbGwtb3BhY2l0eT0iMC4zIi8+CjxwYXRoIGQ9Ik0zNi41NzIzIDBWMjkuNzQxOEg1MC4yODY1TDQxLjE0MzYgNDkuNTY5Nkg1NC44NTc5TDY0LjAwMDggMjkuNzQxOFYwSDM2LjU3MjNaIiBmaWxsPSIjNTYyNDQyIiBmaWxsLW9wYWNpdHk9IjAuMyIvPgo8L3N2Zz4K');
        position: absolute; }
    &::before {
        right: 0;
        top: -40px; }
    &::after {
        left: 0;
        bottom: -80px;
        transform: rotate(-180deg); }
    &.reviews__rotate_reverse {
        .reviews__card {
            .card-wrapper {
                .card-content {
                    clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%); } }
            &::after {
                clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%); } }
        &::before {
            top: -70px; } }
    &.reviews__flex_grow {
        .reviews__card {
            flex: 1 0 auto;
            max-width: 100%; } }
    &.reviews-slider {
        overflow: hidden;
        .item.swiper-slide {
            height: auto;
            .reviews__card {
                height: 100%; } }
        &::before, &::after {
            content: none; } } }

.reviews-outer-wrapper {
    .swiper-button-prev {
        left: -25px;
        // @media (max-width: 1256px)
        //     left: -9px
        // @media (max-width: 1030px)
        //     display: none
        // @media (max-width: 991px)
        //     display: flex
        //     left: -9px
        // @media (max-width: 767px)
 }        //     padding-right: 10px
    .swiper-button-next {
        @media (max-width: 1256px) {
            right: -36px; } }

    &::before, &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA2NCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMjkuNzQxOEgxMy43MTQzTDQuNTcxMzUgNDkuNTY5NkgxOC4yODU2TDI3LjQyODUgMjkuNzQxOFYwSDBWMjkuNzQxOFoiIGZpbGw9IiM1NjI0NDIiIGZpbGwtb3BhY2l0eT0iMC4zIi8+CjxwYXRoIGQ9Ik0zNi41NzIzIDBWMjkuNzQxOEg1MC4yODY1TDQxLjE0MzYgNDkuNTY5Nkg1NC44NTc5TDY0LjAwMDggMjkuNzQxOFYwSDM2LjU3MjNaIiBmaWxsPSIjNTYyNDQyIiBmaWxsLW9wYWNpdHk9IjAuMyIvPgo8L3N2Zz4K');
        position: absolute; }
    &::before {
        right: 0;
        top: -40px;
        @media (max-width: 767px) {
            top: -20px; } }
    &::after {
        left: 0;
        bottom: -80px;
        transform: rotate(-180deg);
        @media (max-width: 767px) {
            bottom: -40px; } } }

.slide-block_color_light:not(.slide-block_color_light_purpure-content) {
    .reviews {
        &::before, &::after {
            filter: contrast(0.2); } } }
