.mobile-menu-fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: $primary-color-bg-dark;
    z-index: 1000;
    transform: translateY(0);
    @include transition(200ms);
    &.hided {
        transform: translateY(-30px);
        opacity: 0; }
    .btn-close {
        padding: 20px;
        position: absolute;
        right: 0;
        top: 0; }
    .content {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        padding: 25px 15px;
        .nav {
            .nav-item {
                .nav-link {
                    font-family: $font-roboto-condensed;
                    font-size: 20px;
                    color: $font-color-white;
                    padding: .8rem 1rem; } } } } }
