.quize {
    .quize__item {
        transform: translateY(0);
        @include transition(200ms);
        .quize-header {
            font-family: $font-open-sans;
            font-weight: 300;
            font-size: 36px;
            .name {
                display: inline-block;
                color: #230F1B; }
            .counter {
                display: inline-block;
                color: #FF6A6A; } }
        .quize-content {
            padding-left: 60px;
            padding-top: 30px;
            padding-bottom: 60px;
            @media (max-width: 767px) {
                padding-left: 0; } }

        .info-icon {
            display: flex;
            align-items: center;
            position: absolute;
            left: 104%;
            top: -20px;
            height: 50px;
            .text {
                flex: 0 0 200px;
                background: #FFFFFF;
                font-family: $font-roboto;
                font-size: 14px;
                line-height: 16px;
                color: rgba(138, 58, 106, 0.5);
                padding: 10px 7px;
                position: relative;
                z-index: 13;
                @include transition(200ms);
                opacity: 0;
                @media (min-width: 768px) {
                    transform: translateX(-20px);
                    &:hover {
                        opacity: 1;
                        transform: translateX(0); } }
                @media (max-width: 767px) {
                    display: none;
                    &.show {
                        display: block; } } }
            .icon {
                flex: 0 0 auto;
                padding-right: 10px;
                @media (min-width: 768px) {
                    &:hover {
                        & + .text {
                            opacity: 1;
                            transform: translateX(0); } } } } }
        .form-check {
            margin-bottom: 30px; }
        .quize-final {
            padding-top: 30px;
            padding-bottom: 60px;
            text-align: center;
            .header {
                font-family: $font-open-sans;
                font-weight: 300;
                font-size: 68px;
                color: #230F1B; }
            .text {
                font-family: $font-roboto;
                font-size: 18px;
                text-align: center;
                color: #562442;
                margin-bottom: 100px; } }

        &.hiden {
            transform: translateY(-30px);
            opacity: 0; } }
    .bottom-buttons {
        padding-left: 60px;
        transform: translateY(0);
        @include transition(200ms);
        &.hiden {
            transform: translateY(-30px);
            opacity: 0; }
        @media (max-width: 767px) {
            padding-left: 0; } }
    .quize-button {
        font-weight: normal;
        font-size: 18px;
        color: $font-color-white;
        opacity: 1;
        transform: translateX(0);
        @include transition(200ms);
        &.hiden {
            transform: translateX(-20px);
            opacity: 0; }
        &.quize-button-prev, &.quize-button-next, &.quize-button-final {
            &::before, &::after {
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAzOCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2Ljc4MjIgOC4yMDcxMUMzNy4xNzI3IDcuODE2NTggMzcuMTcyNyA3LjE4MzQyIDM2Ljc4MjIgNi43OTI4OUwzMC40MTgyIDAuNDI4OTMyQzMwLjAyNzcgMC4wMzg0MDc4IDI5LjM5NDUgMC4wMzg0MDc4IDI5LjAwNCAwLjQyODkzMkMyOC42MTM1IDAuODE5NDU3IDI4LjYxMzUgMS40NTI2MiAyOS4wMDQgMS44NDMxNUwzNC42NjA5IDcuNUwyOS4wMDQgMTMuMTU2OUMyOC42MTM1IDEzLjU0NzQgMjguNjEzNSAxNC4xODA1IDI5LjAwNCAxNC41NzExQzI5LjM5NDUgMTQuOTYxNiAzMC4wMjc3IDE0Ljk2MTYgMzAuNDE4MiAxNC41NzExTDM2Ljc4MjIgOC4yMDcxMVpNMC4zNTU0NjkgOC41SDM2LjA3NTFWNi41SDAuMzU1NDY5VjguNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
                display: inline-block;
                @include transition(200ms);
                transform: translateX(0);
                position: relative; } }
        &.quize-button-prev {
            &::after {
                content: none; }
            &::before {
                transform: rotate(180deg);
                padding-left: 10px;
                top: -1px; }
            &:hover {
                &::before {
                    transform: rotate(180deg) translate(20px); } } }
        &.quize-button-next, &.quize-button-final {
            //
            &::before {
                content: none; }
            &::after {
                padding-left: 10px;
                top: 1px; }
            &:hover {
                &::after {
                    transform: translate(20px); } } }
        &:hover {
            color: $font-color-white; } } }



