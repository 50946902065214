.footer {
    .footer__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
        border-bottom: 1px solid #E1E8F1;
        @media (max-width: 767px) {
            justify-content: flex-start; }
        .header {
            flex: 0 0 auto;
            max-width: 100%;
            font-family: $font-open-sans;
            font-weight: 300;
            font-size: 68px;
            color: $font-color-white;
            padding-right: 30px;
            @media (max-width: 1199px) {
                font-size: 58px; }
            @media (max-width: 991px) {
                font-size: 38px; } }
        .footer__header-button {
            flex: 0 0 auto;
            max-width: 100%;
            @media (max-width: 767px) {
                margin-top: 20px; } } }
    .footer__nav {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
        opacity: 1;
        transform: translateY(0);
        @include transition(200ms);
        .nav {
            .nav-item {
                .nav-link {
                    font-family: $font-roboto;
                    font-size: 16px;
                    text-decoration-line: underline;
                    color: $font-color-white;
                    @include transition(200ms);
                    @media (max-width: 767px) {
                            padding-left: 0;
                            padding-right: 0; }
                    &:hover {
                        text-decoration-line: none;
                        opacity: .9; } } } }
        &.hiden {
            transform: translateY(-30px);
            opacity: 0; } }
    .footer_docs {
        margin-bottom: 50px;
        .footer__header {
            justify-content: flex-start;
            margin-bottom: 50px;
            .header {
                padding-left: 50px; } }
        .img {
            img {
                width: 100%; } }
        .footer__nav {
            margin: 0;
            padding: 0;
            .nav {
                .nav-item {
                    .nav-link {
                        font-size: 16px;
                        @media (max-width: 767px) {
                            padding-left: 0;
                            padding-right: 0; } } } } }
        .socials-vertical {
            display: flex;
            flex-direction: column;
            @media (max-width: 767px) {
                flex-direction: initial;
                justify-content: space-between; }
            a {
                flex: 0 0 100%;
                width: 100%;
                margin-bottom: 10px;
                text-align: right;
                @include transition(200ms);
                @media (max-width: 767px) {
                    text-align: left;
                    flex: 0 0 auto;
                    width: auto;
                    max-width: 100%; }
                img {
                    max-width: 100%; }
                &:hover {
                    opacity: .9; } } }
        .comments-text, .comments-text a {
            font-family: $font-roboto;
            font-size: 16px;
            line-height: 150%;
            color: $font-color-white;
            opacity: 0.65; }
        .comments-text a {
            text-decoration: underline;
            opacity: 1; }
        .copyright {
            font-family: $font-roboto;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: $font-color-white;
            opacity: 0.6;
            @media (max-width: 767px) {
                text-align: left; } } }

    .slide-block__content {
        padding-bottom: 0; } }

