.portfolio {
    .container {
        padding: 0;
        @media (max-width: 575px) {
            padding-right: 15px;
            padding-left: 15px; } }
    .portfolio__menu {
        padding-bottom: 10px;
        margin-bottom: 90px;
        @media (max-width: 425px) {
            margin-bottom: 50px; }
        .nav {
            .nav-item {
                .nav-link {
                    font-family: $font-open-sans;
                    font-size: 24px;
                    text-transform: uppercase;
                    color: $font-color-white;
                    // padding: 0 45px
                    // margin-bottom: 10px
                    padding: .1rem 1rem;
                    opacity: 0.5;
                    @include transition(200ms);
                    &.active, &:hover {
                        opacity: 1; }
                    @media (max-width: 767px) {
                        font-size: 18px; } } } }
        .border-bottom-line {
            padding: 0 1rem;
            padding-top: 30px;
            &::after {
                content: '';
                border-bottom: 1px solid $primary-color-white;
                display: block; } } }
    .profi-cards {
        margin-bottom: 40px; }
    .more-button-row {
        padding-bottom: 130px;
        @media (max-width: 425px) {
            padding-bottom: 80px; } } }

