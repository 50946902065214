.profi-cards {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 37px;
    text-align: left;
    .profi-cards__item {
        flex: 0 0 33%;
        max-width: 33%;
        padding: 0 20px;
        position: relative;
        cursor: pointer;
        @include transition(200ms);
        @media (max-width: 767px) {
            flex: 0 0 100%;
            max-width: 100%;
            // padding: 0 90px
            padding-left: 64px;
            padding-right: 116px;
            margin-bottom: 90px; }
        @media (max-width: 425px) {
            padding-left: 24px;
            padding-right: 69px; }
        .item-container {
            position: relative;
            .img-wrapper {
                position: relative;
                z-index: 2; }
            .content-wrapper {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                transform: rotate(0deg);
                z-index: 1; }
            .img {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                position: relative;
                // z-index: 2
                @include transition(250ms);
                transform: perspective(800px) rotate3d(0,-1,0,22deg) translate(0px, 0px) scale(1);
                &::after {
                    content: '';
                    display: inline-block;
                    padding-top: 120%; } }
            .content {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                background: rgba(0, 0, 0, 0.37);
                z-index: 1;
                overflow: hidden;
                @include transition(300ms);
                transform: perspective(800px) rotate3d(0,-1,0,22deg) translate(30px, 30px) scale(1);
                @media (max-width: 1199px) and (min-width: 426px) {
                    transform: perspective(800px) rotate3d(0,-1,0,22deg) translate(25px, 25px) scale(1); }
                .right {
                    font-family: $font-open-sans;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: right;
                    width: 100%;
                    font-weight: 300;
                    padding: 5px;
                    color: $font-color-white-opacity;
                    transform: rotate(-90deg);
                    position: absolute;
                    right: calc(-50% + 15px);
                    top: calc(40% + -15px);
                    @media (max-width: 1199px) and (min-width: 426px) {
                        font-size: 12px; } }
                .bottom {
                    font-family: $font-open-sans;
                    font-size: 16px;
                    line-height: 22px;
                    margin-top: calc(120% - 24px);
                    color: $font-color-white;
                    padding: 5px;
                    @include transition(300ms);
                    @media (max-width: 1199px) and (min-width: 426px) {
                        font-size: 14px; } } } }
        a.abs-href {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 25; }
        &:hover {
            .item-container {
                .img {
                    transform: perspective(800px) rotate3d(0,-1,0,22deg) translate(15px, 15px) scale(1.01); }
                .content {
                    transform: perspective(800px) rotate3d(0,-1,0,22deg) translate(20px, 19px) scale(.99); } } }
        &.d-none-pagination {
            display: none; } }
    // .profi-cards modifications
    &.profi-cards__rotate_reverse .profi-cards__item, .profi-cards__item.profi-cards__item_rotate_reverse {
        @media (max-width: 767px) {
            padding-right: 83px;
            padding-left: 88px; }
        @media (max-width: 425px) {
            padding-right: 41.5px;
            padding-left: 44px; }
        @media (max-width: 340px) {
            padding-right: 52.5px;
            padding-left: 33px; }
        .item-container {
            .img {
                transform: perspective(800px) rotate3d(0,-1,0,-22deg) translate(0px, 0px) scale(1); }
            .content {
                transform: perspective(800px) rotate3d(0,-1,0,-22deg) translate(30px, 30px) scale(1);
                @media (max-width: 1199px) and (min-width: 426px) {
                    transform: perspective(800px) rotate3d(0,-1,0,-22deg) translate(25px, 25px) scale(1); } } }
        &:hover {
            .item-container {
                .img {
                    transform: perspective(800px) rotate3d(0,-1,0,-22deg) translate(15px, 15px) scale(1.01); }
                .content {
                    transform: perspective(800px) rotate3d(0,-1,0,-22deg) translate(20px, 19px) scale(.99); } } }
        &.profi-cards__item_big-name {
            .item-container {
                .content {
                    .bottom {
                        opacity: 1;
                        max-height: 100px; } } }
            &:hover {
                .content {
                    .bottom {
                        max-height: 33px;
                        opacity: 0; } } } } }
    &.profi-cards__flex_grow {
        .profi-cards__item {
            flex: 1 0 auto;
            max-width: 100%; } }
    &.profi-cards__flex_basis_20 {
        .profi-cards__item {
            flex: 0 0 20%;
            max-width: 20%;
            margin-bottom: 90px;
            padding: 0 20px;
            @media (max-width: 1199px) {
                flex: 0 0 25%;
                max-width: 25%; }
            @media (max-width: 991px) {
                flex: 0 0 33.3%;
                max-width: 33.3%; }
            @media (max-width: 767px) {
                flex: 0 0 50%;
                max-width: 50%; }
            @media (max-width: 575px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 64px;
                padding-right: 116px; }
            @media (max-width: 425px) {
                padding-left: 24px;
                padding-right: 69px; }
            &.profi-cards__item_rotate_reverse {
                @media (max-width: 575px) {
                    padding-right: 83px;
                    padding-left: 88px; }
                @media (max-width: 425px) {
                    padding-right: 41.5px;
                    padding-left: 44px; }
                @media (max-width: 340px) {
                    padding-right: 52.5px;
                    padding-left: 33px; } } } }
    &.profi-cards-slider {
        padding-bottom: 41px;
        padding-top: 5px;
        margin-right: -8px;
        overflow: hidden;
        @media (max-width: 767px) {
            padding-bottom: 5px;
            padding-top: 25px; }
        .item.swiper-slide {
            padding-right: 9px;
            .profi-cards__item {
                padding-left: 19px;
                @media (max-width: 767px) {
                    margin-bottom: 50px; } } } } }




// Modificators for '.profi-cards__item'
.profi-cards {
    .profi-cards__item {
        &.profi-cards__item_big-name {
            padding-bottom: 50px;
            .item-container {
                .content {
                    .bottom {
                        opacity: 1;
                        max-height: 100px; } } }
            &:hover {
                .content {
                    .bottom {
                        max-height: 33px;
                        opacity: 0; } } } }
        &.profi-cards__item_rotate_cancel {
            .item-container {
                .img {
                    transform: perspective(800px) rotate3d(0,-1,0,0) translate(0px, 0px) scale(1); }
                .content {
                    transform: perspective(800px) rotate3d(0,-1,0,0) translate(30px, 30px) scale(1);
                    @media (max-width: 1199px) and (min-width: 426px) {
                        transform: perspective(800px) rotate3d(0,-1,0,0) translate(25px, 25px) scale(1); } } }
            &:hover {
                .item-container {
                    .img {
                        transform: perspective(800px) rotate3d(0,-1,0,0) translate(15px, 15px) scale(1.01); }
                    .content {
                        transform: perspective(800px) rotate3d(0,-1,0,0) translate(20px, 19px) scale(.99); } } } } } }


.profi-cards-outer-wrapper {
    position: relative;
    @media (max-width: 767px) {
        text-align: center; }
    .swiper-button-prev {
        left: -36px;
        @media (max-width: 1256px) {
            left: -9px; } }
    .swiper-button-next {
        right: -70px;
        @media (max-width: 1256px) {
            right: -44px; }
        @media (max-width: 991px) {
            right: -38px; } } }

