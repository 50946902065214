.reviews-page {
    position: relative;
    .project-name {
        font-family: $font-roboto;
        font-size: 18px;
        line-height: 150%;
        color: #212121;
        font-weight: 700;
        padding-bottom: 50px;
        span {
            text-transform: uppercase; }
        a {
            color: #212121;
            text-decoration: underline; } }
    .text {
        font-family: $font-roboto;
        font-size: 18px;
        line-height: 150%;
        color: #212121;
        padding-bottom: 30px; }
    .between {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .name {
            flex: 0 0 auto;
            flex-wrap: wrap;
            font-family: $font-roboto;
            font-size: 16px;
            line-height: 150%;
            color: #212121;
            font-weight: 700; }
        .date {
            flex: 0 0 auto;
            flex-wrap: wrap;
            font-family: $font-roboto;
            font-size: 16px;
            line-height: 150%;
            color: #212121; } }
    .company {
        font-family: $font-roboto;
        font-size: 16px;
        line-height: 150%;
        color: #212121; }
    &::before, &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA2NCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMjkuNzQxOEgxMy43MTQzTDQuNTcxMzUgNDkuNTY5NkgxOC4yODU2TDI3LjQyODUgMjkuNzQxOFYwSDBWMjkuNzQxOFoiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuMTMiLz4KPHBhdGggZD0iTTM2LjU3MjMgMFYyOS43NDE4SDUwLjI4NjVMNDEuMTQzNiA0OS41Njk2SDU0Ljg1NzlMNjQuMDAwOCAyOS43NDE4VjBIMzYuNTcyM1oiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuMTMiLz4KPC9zdmc+Cg==');
        position: absolute;
        @media (max-width: 999px) {
            display: none; }
        @media (max-width: 991px) {
            display: inline; }
        @media (max-width: 590px) {
            display: none; } }
    &::before {
        right: -80px;
        top: -10px;
        @media (max-width: 1280px) {
            right: -20px; }
        @media (max-width: 1100px) {
            right: -20px; }
        @media (max-width: 991px) {
            right: -80px; }
        @media (max-width: 865px) {
            right: -20px; } }
    &::after {
        left: -100px;
        bottom: -2px;
        transform: rotate(-180deg);
        @media (max-width: 1320px) {
            left: -80px; }
        @media (max-width: 1280px) {
            left: -30px;
            bottom: -60px; }
        @media (max-width: 1199px) {
            left: -100px; }
        @media (max-width: 1150px) {
            left: -80px; }
        @media (max-width: 1100px) {
            left: -30px;
            bottom: -60px; }
        @media (max-width: 991px) {
            left: -100px;
            bottom: -2px; }
        @media (max-width: 900px) {
            left: -80px; }
        @media (max-width: 865px) {
            left: -30px;
            bottom: -60px; } }

    &.reviews-page__portfolio {
        &::before {
            top: -60px; } } }

