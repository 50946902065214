.count-list-items {
    .item-header {
        padding-left: 47px; }
    .text {
        padding-left: 47px; }

    .count-list-items__item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        align-items: center;
        .count {
            flex: 0 0 auto;
            max-width: 100%;
            padding-right: 20px;
            font-family: $font-roboto;
            font-weight: 900;
            font-size: 24px;
            color: $font-color-white; }
        .text {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            padding-left: 0; }
        &.count-list-items__item_width_less {
            width: 40%; } } }





