.offers {
    .underheader {
        font-family: $font-open-sans;
        font-size: 22px;
        line-height: 22px;
        color: $font-color-white-opacity;
        @include transition(300ms);
        transform: translateY(0);
        opacity: 1;
        &.hidden {
            transform: translateY(-20px);
            opacity: 0; } }
    .header {
        font-family: $font-open-sans;
        color: $font-color-white;
        font-weight: 700;
        font-size: 68px;
        line-height: 68px;
        text-transform: uppercase;
        position: relative;
        left: -1px;
        padding-top: 3px;
        padding-bottom: 5px;
        @include transition(300ms);
        transform: translateY(0);
        opacity: 1;
        @media (max-width: 991px) {
            font-size: 58px;
            line-height: 58px; }
        @media (max-width: 767px) {
            font-size: 43px;
            line-height: 43px; }
        &.header__pb_more {
            padding-bottom: 20px; }
        &.header__size_less {
            font-size: 48px;
            @media (max-width: 991px) {
                font-size: 38px;
                line-height: 38px; }
            @media (max-width: 767px) {
                font-size: 30px;
                line-height: 30px; } }
        &.header__fs_mobile-less {
            @media (max-width: 575px) {
                font-size: 30px;
                line-height: 30px; } }
        &.hidden {
            transform: translateY(-20px);
            opacity: 0; } }
    .subheader {
        font-family: $font-open-sans;
        color: $font-color-white-opacity;
        font-size: 41px;
        line-height: 41px;
        @include transition(300ms);
        transform: translateY(0);
        opacity: 1;
        @media (max-width: 991px) {
            font-size: 32px;
            line-height: 32px; }
        @media (max-width: 767px) {
            padding-top: 10px; }
        span {
            font-weight: 800;
            color: $font-color-white;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                bottom: 5px;
                left: 0px;
                width: 102%;
                // right: -2px
                height: 2px;
                background: $font-color-white;
                @include transition(300ms); }
            &.hidden-line {
                &::after {
                    width: 0%; } }
            @media (max-width: 767px) {
                display: inline-block;
                padding-top: 10px;
                &::after {
                    bottom: -5px; } } }
        &.header__fs_mobile-less {
            font-size: 26px;
            line-height: 26px;
            padding-top: 0; }
        &.hidden {
            transform: translateY(-20px);
            opacity: 0; } }
    &.offers__header-dark {
        .header {
            color: #230F1B; } }
    &.offers__subheader-white {
        .subheader {
            color: $font-color-white; } } }

// Colors
.header.header__color_aqua-blue {
    .offers {
        .header {
            color: $primary-color-block-aqua-blue; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_purpure {
    .offers {
        .header {
            color: $primary-color-block-purpure; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_orange-dirty {
    .offers {
        .header {
            color: $primary-color-block-orange-dirty; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_dark-blue {
    .offers {
        .header {
            color: $primary-color-block-dark-blue; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_green-natural {
    .offers {
        .header {
            color: $primary-color-block-green-natural; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_green {
    .offers {
        .header {
            color: $primary-color-block-green; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_yellow {
    .offers {
        .header {
            color: $primary-color-block-yellow; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_yellow-grey {
    .offers {
        .header {
            color: $primary-color-block-yellow-grey; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_orange {
    .offers {
        .header {
            color: $primary-color-block-orange; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_green-lighter {
    .offers {
        .header {
            color: $primary-color-block-green-lighter; }
        .subheader {
            color: $font-color-white; } } }
.header.header__color_orange-bright {
    .offers {
        .header {
            color: $primary-color-block-orange-bright; }
        .subheader {
            color: $font-color-white; } } }
