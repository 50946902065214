@import './_variables';

@import './_button';
@import './_form_profi';

// Header
@import './_header';
@import './_header_top_bar';
@import './_header_offers';
@import './_header_bottom_features';
@import './_mobile_menu_fixed';

// Slides blocks
@import './_slide_block';
@import './_slide_block_contacts_texts';
@import './_slide_block_overlay';
@import './_profi_cards';

@import './_block_header';
@import './_arrows_list';
@import './_arrow_list_items';
@import './_count_list_items';
@import './_reviews';
@import './_reviews_page';
@import './_prices';
@import './_scope_of_work';
@import './_questions_accordion';

@import './_portfolio';
@import './_portfolio_page';

@import './_modal';

@import './_cms_slider';
@import './_quize';

@import './_footer';

