.profi-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .85);
    z-index: 1000;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include transition(200ms);
    opacity: 0;
    .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1001; }
    .profi-modal__window {
        flex: 0 0 330px;
        background: $primary-color-white;
        margin: 30px 0;
        @include transition(200ms);
        opacity: 0;
        transform: translateY(-50px);
        position: relative;
        z-index: 1002;
        @media (max-width: 374px) {
            flex: 0 0 95%; }
        .content {
            padding: 50px 30px;
            padding-bottom: 25px;
            position: relative;
            .header {
                font-family: $font-roboto;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: $primary-color-orange;
                margin-bottom: 30px; }
            .close-button {
                padding: 5px;
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer; }
            .success-content {
                text-align: center;
                padding: 75px 0;
                .line {
                    font-family: $font-roboto;
                    font-size: 24px;
                    line-height: 28px;
                    color: #3D9B03;
                    padding-top: 20px; } }
            .form-profi {
                input {
                    border-color: $primary-color-orange;
                    color: $font-color-black;
                    &::placeholder {
                        color: #606060; } }
                .policy, .policy a {
                    text-align: center;
                    color: #606060; }
                &.form-profi__error {
                    input[required].form-profi__item_error {
                        color: $font-color-red;
                        border-color: $font-color-red; }
                    .error-line {
                        text-align: center;
                        display: block;
                        width: 100%; } } } } }
    &.show {
        opacity: 1;
        .profi-modal__window {
            opacity: 1;
            transform: translateY(0); } } }




