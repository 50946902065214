.top-bar {
    @media (max-width: 767px) {
        padding-top: 10px; }
    .top-bar__logo {
        padding-top: 15px;
        svg {
            .svg-fill {
                fill: $primary-color-white;
                @include transition(300ms); }
            .svg-stroke {
                stroke: $primary-color-white;
                @include transition(300ms); }
            &.dark {
                .svg-fill {
                    fill: $primary-color-black; }
                .svg-stroke {
                    stroke: $primary-color-black; } }
            &.hover_aqua-blue {
                &:hover {
                    .svg-fill {
                        fill: $primary-color-block-aqua-blue; }
                    .svg-stroke {
                        stroke: $primary-color-block-aqua-blue; } } }
            &.hover_purpure {
                &:hover {
                    .svg-fill {
                        fill: $primary-color-block-purpure; }
                    .svg-stroke {
                        stroke: $primary-color-block-purpure; } } }
            &.hover_dark-blue {
                &:hover {
                    .svg-fill {
                        fill: $primary-color-block-dark-blue; }
                    .svg-stroke {
                        stroke: $primary-color-block-dark-blue; } } }
            &.hover_green {
                &:hover {
                    .svg-fill {
                        fill: $primary-color-block-green; }
                    .svg-stroke {
                        stroke: $primary-color-block-green; } } }
            &.hover_orange-dirty {
                &:hover {
                    .svg-fill {
                        fill: $primary-color-block-orange-dirty; }
                    .svg-stroke {
                        stroke: $primary-color-block-orange-dirty; } } }
            &.hover_yellow-grey {
                &:hover {
                    .svg-fill {
                        fill: $primary-color-block-yellow-grey; }
                    .svg-stroke {
                        stroke: $primary-color-block-yellow-grey; } } }
            &.hover_yellow {
                &:hover {
                    .svg-fill {
                        fill: $primary-color-block-yellow; }
                    .svg-stroke {
                        stroke: $primary-color-block-yellow; } } }
            @media (max-width: 991px) {
                max-width: 50px; }
            @media (max-width: 767px) {
                max-width: unset; } }

        @media (max-width: 575px) {
            padding-top: 0; }
        img {
            max-width: 100%; } }
    .top-bar__nav {
        .nav {
            .nav-item {
                padding-top: 26px;
                position: relative;
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
                text-align: center;
                .hover {
                    opacity: 0;
                    transform: translateY(-30px);
                    @include transition(200ms);
                    background: $primary-color-block-orange;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: -4px;
                    z-index: 1;
                    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.35);
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        left: 0;
                        bottom: -10px;
                        height: 20px;
                        background: $primary-color-block-orange;
                        clip-path: polygon(50% 0%, 100% 50%, 50% 90%, 0% 50%);
                        @include transition(300ms); }
                    &.hover_color_aqua-blue, &.hover_color_aqua-blue::after {
                        background: $primary-color-block-aqua-blue; }
                    &.hover_color_purpure, &.hover_color_purpure::after {
                        background: $primary-color-block-purpure; }
                    &.hover_color_orange-dirty, &.hover_color_orange-dirty::after {
                        background: $primary-color-block-orange-dirty; }
                    &.hover_color_dark-blue, &.hover_color_dark-blue::after {
                        background: $primary-color-block-dark-blue; }
                    &.hover_color_green, &.hover_color_green::after {
                        background: $primary-color-block-green; }
                    &.hover_color_yellow, &.hover_color_yellow::after {
                        background: $primary-color-block-yellow; }
                    &.hover_color_yellow, &.hover_color_yellow::after {
                        background: $primary-color-block-yellow; }
                    &.hover_color_yellow-grey, &.hover_color_yellow-grey::after {
                        background: $primary-color-block-yellow-grey; } }
                .nav-link {
                    font-family: $font-roboto-condensed;
                    font-size: 17px;
                    line-height: 20px;
                    color: $font-color-white;
                    position: relative;
                    text-transform: uppercase;
                    z-index: 2;
                    padding: 0;
                    @media (max-width: 991px) {
                        font-size: 15px; } }
                &.active, &:hover {
                    .hover {
                        opacity: 1;
                        transform: translateY(0); } } } } }
    .top-bar__contacts {
        text-align: right;
        padding-top: 24px;
        .tel {
            a {
                font-family: $font-open-sans;
                font-weight: 600;
                font-size: 26px;
                line-height: 26px;
                color: $font-color-white;
                &:hover {
                    text-decoration: none; }
                @media (max-width: 1199px) {
                    font-size: 24px; }
                @media (max-width: 991px) {
                    font-size: 17px; } } }
        .btn {
            margin-top: 13px; }
        .btn.btn__size_large {
            padding: 11px 20px; } }
    .row {
        @media (max-width: 767px) {
            align-items: center; } } }

