.arrow-list-items {
    .item-header {
        padding-left: 70px; }
    .text {
        padding-left: 70px; }

    .arrow-list-items__item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .icon {
            flex: 0 0 auto;
            max-width: 100%;
            padding-right: 35px;
            svg {
                path {
                    fill: $primary-color-white; } } }
        .text {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            padding-left: 0; }
        &.arrow-list-items__item_width_less {
            width: 40%;
            @media (max-width: 991px) {
                width: 80%; }
            @media (max-width: 767px) {
                width: 100%; } } } }






