.arrows-list {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    .arrows-list__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .arrow {
            flex: 0 0 auto;
            max-width: 10%;
            &::before {
                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM3LjIwOCA4LjM3MTY2QzM3LjU5ODUgNy45ODExMyAzNy41OTg1IDcuMzQ3OTcgMzcuMjA4IDYuOTU3NDRMMzAuODQ0IDAuNTkzNDgzQzMwLjQ1MzUgMC4yMDI5NTkgMjkuODIwMyAwLjIwMjk1OSAyOS40Mjk4IDAuNTkzNDgzQzI5LjAzOTMgMC45ODQwMDcgMjkuMDM5MyAxLjYxNzE3IDI5LjQyOTggMi4wMDc3TDM1LjA4NjYgNy42NjQ1NUwyOS40Mjk4IDEzLjMyMTRDMjkuMDM5MyAxMy43MTE5IDI5LjAzOTMgMTQuMzQ1MSAyOS40Mjk4IDE0LjczNTZDMjkuODIwMyAxNS4xMjYxIDMwLjQ1MzUgMTUuMTI2MSAzMC44NDQgMTQuNzM1NkwzNy4yMDggOC4zNzE2NlpNMC43ODEyNSA4LjY2NDU1SDM2LjUwMDhWNi42NjQ1NUgwLjc4MTI1VjguNjY0NTVaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K'); } }
        .text {
            flex: 0 0 80%;
            max-width: 80%;
            font-family: $font-roboto;
            font-size: 20px;
            line-height: 150%;
            color: $font-color-white; }
        &:last-child {
            margin-bottom: 0; } }
    &.arrows-list__pr-md {
        padding-right: 70px;
 } }        // TODO: add media..
