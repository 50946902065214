.scope-of-work {
    .scope-of-work__headers {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $primary-color-white;
        @media (max-width: 767px) {
          border-bottom: none;
          margin: 0;
          padding: 0; } }
    .scope-of-work__contents {
        .text {
            font-family: $font-roboto;
            font-size: 16px;
            line-height: 150%;
            color: #230F1B;
            @media (max-width: 767px) {
              padding-bottom: 20px; } } }
    .scope-of-work__headers, .scope-of-work__contents {
        .name, .name a {
            font-family: $font-roboto;
            font-size: 25px;
            line-height: 150%;
            color: $font-color-white;
            @media (max-width: 767px) {
              padding-bottom: 10px; } }
        .name a {
            text-decoration: underline; } }
    .image-chart {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%; } }
