$prices-content-padding-hor: 10px;
.prices {
    .prices__menu {
        padding-bottom: 25px;
        margin-bottom: 20px;
        border-bottom: 1px solid $primary-color-white;
        .nav {
            .nav-item {
                .nav-link {
                    font-family: $font-open-sans;
                    font-size: 24px;
                    text-transform: uppercase;
                    color: $font-color-white;
                    padding: 0 45px;
                    opacity: 0.5;
                    @include transition(200ms);
                    @media (max-width: 767px) {
                        padding: 0 18px;
                        .link-after::after {
                            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjA2NzYgMC45NzQ2MDlIMTIuNDIyNkMxMS44OTQ4IDAuOTc0NjA5IDExLjQ2NyAxLjQwMjQ3IDExLjQ2NyAxLjkzMDI0QzExLjQ2NyAyLjQ1ODAxIDExLjg5NDggMi44ODU4NyAxMi40MjI2IDIuODg1ODdIMTcuNzYwNUw3LjkyNDQ0IDEyLjcyMkM3LjU1MTIzIDEzLjA5NTIgNy41NTEyMyAxMy43MDAyIDcuOTI0NDQgMTQuMDczNEM4LjExMDk4IDE0LjI2IDguMzU1NTUgMTQuMzUzMyA4LjYwMDExIDE0LjM1MzNDOC44NDQ2NyAxNC4zNTMzIDkuMDg5MjggMTQuMjYgOS4yNzU4NiAxNC4wNzM0TDE5LjExMiA0LjIzNzNWOS41NzUyQzE5LjExMiAxMC4xMDMgMTkuNTM5OCAxMC41MzA4IDIwLjA2NzYgMTAuNTMwOEMyMC41OTU0IDEwLjUzMDggMjEuMDIzMiAxMC4xMDMgMjEuMDIzMiA5LjU3NTJWMS45MzAyNEMyMS4wMjMyIDEuNDAyNDcgMjAuNTk1MyAwLjk3NDYwOSAyMC4wNjc2IDAuOTc0NjA5WiIgZmlsbD0iIzIzMEYxQiIvPgo8cGF0aCBkPSJNMTYuMjQ1NSAxMC41MzI1QzE1LjcxNzcgMTAuNTMyNSAxNS4yODk5IDEwLjk2MDQgMTUuMjg5OSAxMS40ODgyVjIwLjA4ODhIMS45MTEyMlY2LjcxMDA1SDEwLjUxMThDMTEuMDM5NiA2LjcxMDA1IDExLjQ2NzQgNi4yODIxOCAxMS40Njc0IDUuNzU0NDJDMTEuNDY3NCA1LjIyNjY1IDExLjAzOTYgNC43OTg4MyAxMC41MTE4IDQuNzk4ODNIMC45NTU2MzFDMC40Mjc4NjQgNC43OTg4MyAwIDUuMjI2NjkgMCA1Ljc1NDQ2VjIxLjA0NDRDMCAyMS41NzIxIDAuNDI3ODY0IDIyIDAuOTU1NjMxIDIySDE2LjI0NTZDMTYuNzczMyAyMiAxNy4yMDEyIDIxLjU3MjEgMTcuMjAxMiAyMS4wNDQzVjExLjQ4ODJDMTcuMjAxMSAxMC45NjA0IDE2Ljc3MzMgMTAuNTMyNSAxNi4yNDU1IDEwLjUzMjVaIiBmaWxsPSIjMjMwRjFCIi8+Cjwvc3ZnPgo='); } }
                    @media (max-width: 575px) {
                        padding: 0 10px;
                        font-size: 20px; }
                    &.active, &:hover {
                        opacity: 1; } } } } }
    .prices__table {
        @media (max-width: 767px) {
            padding-top: 10px; }
        .prices-row {
            margin-left: -10px;
            margin-right: -10px;
            display: flex;
            flex-wrap: wrap;
            .prices-item {
                flex: 0 0 25%;
                max-width: 25%;
                padding: 10px;
                padding-bottom: 20px;
                display: flex;
                @media (max-width: 767px) {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding-top: 0; }
                @media (max-width: 575px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-top: 0; }
                .content {
                    background: $primary-color-white;
                    text-align: center;
                    padding-top: 20px;
                    flex: 0 0 100%;
                    max-width: 100%;
                    .name {
                        font-family: $font-open-sans;
                        font-size: 24px;
                        text-transform: uppercase;
                        color: $font-color-black;
                        padding-bottom: 15px;
                        padding: 0 $prices-content-padding-hor;
                        @media (max-width: 1199px) {
                            font-size: 20px; }
                        @media (max-width: 991px) {
                            font-size: 14px; }
                        @media (max-width: 767px) {
                            font-size: 22px; } }
                    .price {
                        font-family: $font-roboto;
                        font-weight: 900;
                        font-size: 48px;
                        line-height: 48px;
                        color: $font-color-black;
                        padding: 0 $prices-content-padding-hor;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        @media (max-width: 991px) {
                            font-size: 36px;
                            line-height: 36px; }
                        svg {
                            height: 100%;
                            width: 20px;
                            display: inline-block;
                            margin-left: 4px;
                            margin-top: -10px;
                            @media (max-width: 991px) {
                                width: 16px; } } }
                    .light-desc {
                        font-family: $font-open-sans;
                        font-size: 14px;
                        color: #7A7A7A;
                        padding: 0 $prices-content-padding-hor;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        @media (max-width: 991px) {
                            font-size: 12px; } }
                    .desc {
                        font-family: $font-open-sans;
                        font-size: 16px;
                        color: $font-color-black;
                        padding: 0 $prices-content-padding-hor;
                        padding-top: 20px;
                        min-height: 200px;
                        @media (max-width: 991px) {
                            font-size: 12px; }
                        @media (max-width: 575px) {
                            min-height: auto;
                            padding-bottom: 20px; } }
                    .btn.btn__size_large-width-100 {
                        font-family: $font-open-sans;
                        font-size: 24px;
                        color: $font-color-white;
                        background: $font-color-black;
                        @media (max-width: 991px) {
                            font-size: 16px; } }
                    .btn.btn__size_less-width-100 {
                        font-family: $font-open-sans;
                        padding: 14px 10px;
                        text-transform: uppercase;
                        width: 100%;
                        font-size: 18px;
                        color: $font-color-black;
                        @include transition(400ms);
                        background: none;
                        @media (max-width: 991px) {
                            font-size: 14px; } } }
                // colors
                &.prices-item__color_aqua-blue {
                    .content {
                        .price {
                            color: $primary-color-block-aqua-blue;
                            svg {
                                path {
                                    fill: $primary-color-block-aqua-blue; } } }
                        .btn.btn__size_large-width-100 {
                            background: #358e94;
                            &:hover, &:active {
                                background: #247d83; } }
                        .btn.btn__size_less-width-100 {
                            color: #358e94;
                            &:hover, &:active {
                                background: #247d83;
                                color: $font-color-white; } } } }
                &.prices-item__color_purpure {
                    .content {
                        .price {
                            color: $primary-color-block-purpure;
                            svg {
                                path {
                                    fill: $primary-color-block-purpure; } } }
                        .btn.btn__size_large-width-100 {
                            background: #562442;
                            &:hover, &:active {
                                background: #75325A; } }
                        .btn.btn__size_less-width-100 {
                            color: #562442;
                            &:hover, &:active {
                                background: #75325A;
                                color: $font-color-white; } } } }
                &.prices-item__color_orange-dirty {
                    .content {
                        .price {
                            color: $primary-color-block-orange-dirty;
                            svg {
                                path {
                                    fill: $primary-color-block-orange-dirty; } } }
                        .btn.btn__size_large-width-100 {
                            background: #d45f2c;
                            &:hover, &:active {
                                background: #ce5d2c; } }
                        .btn.btn__size_less-width-100 {
                            color: #d45f2c;
                            &:hover, &:active {
                                background: #ce5d2c;
                                color: $font-color-white; } } } }
                &.prices-item__color_dark-blue {
                    .content {
                        .price {
                            color: $primary-color-block-dark-blue;
                            svg {
                                path {
                                    fill: $primary-color-block-dark-blue; } } }
                        .btn.btn__size_large-width-100 {
                            background: #28425e;
                            &:hover, &:active {
                                background: #223f5e; } }
                        .btn.btn__size_less-width-100 {
                            color: #28425e;
                            &:hover, &:active {
                                background: #223f5e;
                                color: $font-color-white; } } } }
                &.prices-item__color_green-natural {
                    .content {
                        .price {
                            color: $primary-color-block-green-natural;
                            svg {
                                path {
                                    fill: $primary-color-block-green-natural; } } }
                        .btn.btn__size_large-width-100 {
                            background: #3a8809;
                            &:hover, &:active {
                                background: #397c0f; } }
                        .btn.btn__size_less-width-100 {
                            color: #3a8809;
                            &:hover, &:active {
                                background: #397c0f;
                                color: $font-color-white; } } } }
                &.prices-item__color_green {
                    .content {
                        .price {
                            color: $primary-color-block-green;
                            svg {
                                path {
                                    fill: $primary-color-block-green; } } }
                        .btn.btn__size_large-width-100 {
                            background: #3a8809;
                            &:hover, &:active {
                                background: #397c0f; } }
                        .btn.btn__size_less-width-100 {
                            color: #3a8809;
                            &:hover, &:active {
                                background: #397c0f;
                                color: $font-color-white; } } } }
                &.prices-item__color_yellow {
                    .content {
                        .price {
                            color: $primary-color-block-yellow;
                            svg {
                                path {
                                    fill: $primary-color-block-yellow; } } }
                        .btn.btn__size_large-width-100 {
                            background: #b19519;
                            &:hover, &:active {
                                background: #8a7517; } }
                        .btn.btn__size_less-width-100 {
                            color: #b19519;
                            &:hover, &:active {
                                background: #8a7517;
                                color: $font-color-white; } } } }
                &.prices-item__color_yellow-grey {
                    .content {
                        .price {
                            color: $primary-color-block-yellow-grey;
                            svg {
                                path {
                                    fill: $primary-color-block-yellow-grey; } } }
                        .btn.btn__size_large-width-100 {
                            background: #b19519;
                            &:hover, &:active {
                                background: #8a7517; } }
                        .btn.btn__size_less-width-100 {
                            color: #b19519;
                            &:hover, &:active {
                                background: #8a7517;
                                color: $font-color-white; } } } }
                &.prices-item__color_orange {
                    .content {
                        .price {
                            color: $primary-color-block-orange;
                            svg {
                                path {
                                    fill: $primary-color-block-orange; } } }
                        .btn.btn__size_large-width-100 {
                            background: #d45f2c;
                            &:hover, &:active {
                                background: #ce5d2c; } }
                        .btn.btn__size_less-width-100 {
                            color: #d45f2c;
                            &:hover, &:active {
                                background: #ce5d2c;
                                color: $font-color-white; } } } }
                &.prices-item__color_green-lighter {
                    .content {
                        .price {
                            color: $primary-color-block-green-lighter;
                            svg {
                                path {
                                    fill: $primary-color-block-green-lighter; } } }
                        .btn.btn__size_large-width-100 {
                            background: #3a8809;
                            &:hover, &:active {
                                background: #397c0f; } }
                        .btn.btn__size_less-width-100 {
                            color: #3a8809;
                            &:hover, &:active {
                                background: #397c0f;
                                color: $font-color-white; } } } }
                &.prices-item__color_orange-bright {
                    .content {
                        .price {
                            color: $primary-color-block-orange-bright;
                            svg {
                                path {
                                    fill: $primary-color-block-orange-bright; } } }
                        .btn.btn__size_large-width-100 {
                            background: #d14512;
                            &:hover, &:active {
                                background: #9e350e; } }
                        .btn.btn__size_less-width-100 {
                            color: #d14512;
                            &:hover, &:active {
                                background: #9e350e;
                                color: $font-color-white; } } } } } } }
    .price-description-block {
        background: $primary-color-white;
        padding: 20px 30px;
        .name {
            font-family: $font-open-sans;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $font-color-black;
            padding-bottom: 5px; }
        .text {
            font-family: $font-open-sans;
            font-size: 14px;
            line-height: 19px;
            color: $font-color-black; } }
    .prices__show-hide-wrapper {
        opacity: 1;
        transform: translateY(0);
        @include transition(200ms);
        &.hiden {
            transform: translateY(-30px);
            opacity: 0; } } }




