$cut-value: 5;
$cut-value-mobile: 2.5;

.slide-block {
    // min-height: 100vh
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 7vh;
    position: relative;
    z-index: 1;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.35);
    .slide-block__content {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 120px 0;
        @media (max-width: 425px) {
            padding: 80px 0; }
        .block-header {
            margin-bottom: 70px;
            @media (max-width: 425px) {
                margin-bottom: 40px; }
            .text {
                font-family: $font-roboto;
                font-size: 16px;
                line-height: 150%;
                color: $font-color-white;
                padding-top: 15px;
                font-weight: 400; }
            &.block-header__pb_less-lg {
                @media (max-width: 991px) {
                    margin-bottom: 40px; } }
            &.block-header__pb_less {
                margin-bottom: 40px;
                @media (max-width: 767px) {
                    margin-bottom: 35px; }
                @media (max-width: 425px) {
                    margin-bottom: 30px; } }
            &.block-header__fs_less-mobile {
                @media (max-width: 575px) {
                    font-size: 32px; } }
            &.block-header__pb_less-60 {
                margin-bottom: 60px; } } }
    .slide-block__text-content {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        height: 100%;
        @media (max-width: 991px) {
            align-items: center;
            margin-bottom: 30px; }
        .text {
            flex: 0 0 100%;
            max-width: 100%;
            font-family: $font-roboto;
            font-size: 18px;
            line-height: 150%;
            color: $font-color-white;
            @media (max-width: 555px) {
                font-size: 16px; }
            &.text__flex-half-991 {
                @media (max-width: 991px) {
                    flex: 0 0 50%;
                    max-width: 50%; }
                @media (max-width: 767px) {
                    flex: 0 0 100%;
                    max-width: 100%; } }
            p {
                font-family: $font-roboto;
                font-size: 18px;
                line-height: 150%;
                color: $font-color-white; } }
        .bottom-part {
            flex: 0 0 100%;
            max-width: 100%;
            @media (max-width: 1199px) {
                margin-top: 40px; }
            @media (max-width: 991px) {
                margin-top: 0;
                margin-bottom: 0;
                flex: 0 0 50%;
                max-width: 50%; }
            @media (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%; } } }
    .slide-block__img-100-width {
        img {
            width: 100%; } }

    &.slide-block__height_auto {
        min-height: auto; }

    // Colors
    // [
    //     'aqua-blue', 'purpure', 'orange-dirty', 'dark-blue', 'green-natural', 'green', 'yellow',
    //     'yellow-grey', 'orange', 'green-lighter', 'orange-bright'
    // ]
    &.slide-block_color_aqua-blue, &.slide-block_color_purpure, &.slide-block_color_orange-dirty, &.slide-block_color_dark-blue, &.slide-block_color_green-natural, &.slide-block_color_green, &.slide-block_color_yellow, &.slide-block_color_yellow-grey, &.slide-block_color_orange, &.slide-block_color_green-lighter, &.slide-block_color_orange-bright, {
        .form-profi {
            input, textarea {
                border-color: #00000070; }
            .btn__red {
                border-color: #00000000;
                background: #00000070;
                &:hover {
                    border-color: #0000003b;
                    background: #0000003b; }
                &:active {
                    border-color: #0000003b;
                    background: #0000003b; } } } }
    &.slide-block_color_aqua-blue {
        background: $primary-color-block-aqua-blue;
        .block-header {
            color: #0E2425; }
        .btn.btn__dark-light {
            border-color: #11292B;
            &:hover, &:active {
                background: #11292B; } } }
    &.slide-block_color_purpure {
        background: $primary-color-block-purpure;
        .block-header {
            color: #230F1B; }
        .btn.btn__dark-light {
            border-color: #4E0C33;
            &:hover, &:active {
                background: #4E0C33; } }
        .form-profi {
            input, textarea {
                border-color: #562442; }
            .btn__red {
                border-color: #562442;
                background: #562442;
                &:hover {
                    border-color: #75325A;
                    background: #75325A; }
                &:active {
                    border-color: #75325A;
                    background: #75325A; } } }
        .reviews {
            .reviews__card {
                &::after {
                    background: #562442; } } } }
    &.slide-block_color_orange-dirty {
        background: $primary-color-block-orange-dirty;
        .block-header {
            color: #441906; }
        .btn.btn__dark-light {
            border-color: #441906;
            &:hover, &:active {
                background: #441906; } } }
    &.slide-block_color_dark-blue {
        background: $primary-color-block-dark-blue;
        .block-header {
            color: #0D131A; }
        .btn.btn__dark-light {
            border-color: #0D131A;
            &:hover, &:active {
                background: #0D131A; } } }
    &.slide-block_color_green-natural {
        background: $primary-color-block-green-natural;
        .block-header {
            color: #0F2701; }
        .btn.btn__dark-light {
            border-color: #173C00;
            &:hover, &:active {
                background: #173C00; } } }
    &.slide-block_color_green {
        background: $primary-color-block-green;
        .block-header {
            color: #0F2701; }
        .btn.btn__dark-light {
            border-color: #173C00;
            &:hover, &:active {
                background: #173C00; } } }
    &.slide-block_color_yellow {
        background: $primary-color-block-yellow;
        .block-header {
            color: #362D06; }
        .btn.btn__dark-light {
            border-color: #362D06;
            &:hover, &:active {
                background: #362D06; } } }
    &.slide-block_color_yellow-grey {
        background: $primary-color-block-yellow-grey;
        .block-header {
            color: #31261B; }
        .btn.btn__dark-light {
            border-color: #31261B;
            &:hover, &:active {
                background: #31261B; } } }
    &.slide-block_color_orange {
        background: $primary-color-block-orange;
        .block-header {
            color: #402300; }
        .btn.btn__dark-light {
            border-color: #402300;
            &:hover, &:active {
                background: #402300; } } }
    &.slide-block_color_green-lighter {
        background: $primary-color-block-green-lighter;
        .block-header {
            color: #082B1E; }
        .btn.btn__dark-light {
            border-color: #082B1E;
            &:hover, &:active {
                background: #082B1E; } } }
    &.slide-block_color_orange-bright {
        background: $primary-color-block-orange-bright;
        .block-header {
            color: #230F1B; } }
    &.slide-block_color_dark {
        background: $primary-color-block-dark; }
    &.slide-block_color_light {
        background: $primary-color-block-light;
        .block-header {
                .text {
                    color: $font-color-black; } }
        .questions-accordion {
            .card {
                .card-header {
                    border-color: $primary-color-black;
                    .btn-name {
                        color: $font-color-black; }
                    .dropdown-icon {
                        &::after {
                            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNSAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeTE9IjE3LjEyNyIgeDI9IjM1IiB5Mj0iMTcuMTI3IiBzdHJva2U9ImJsYWNrIi8+CjxsaW5lIHgxPSIxNyIgeTE9IjM1LjEyNyIgeDI9IjE3IiB5Mj0iMC4xMjY5NTMiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg=='); } } }
                .card-body {
                    color: $font-color-black; }
                &.active {
                    .card-header {
                        .dropdown-icon {
                            &::after {
                                content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDM1IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHkxPSIwLjYyNjk1MyIgeDI9IjM1IiB5Mj0iMC42MjY5NTMiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg=='); } } } }
                &:last-child {
                    border-color: $primary-color-black; } } }
        &.slide-block_color_light_aqua-blue-content, &.slide-block_color_light_purpure-content, &.slide-block_color_light_orange-dirty-content, &.slide-block_color_light_dark-blue-content, &.slide-block_color_light_green-natural-content, &.slide-block_color_light_green-content, &.slide-block_color_light_yellow-content, &.slide-block_color_light_yellow-grey-content, &.slide-block_color_light_orange-content, &.slide-block_color_light_green-lighter-content, &.slide-block_color_light_orange-bright-content {
            .btn.btn__dark-light {
                &::after {
                    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM3LjIwOCA4LjM3MTY2QzM3LjU5ODUgNy45ODExMyAzNy41OTg1IDcuMzQ3OTcgMzcuMjA4IDYuOTU3NDRMMzAuODQ0IDAuNTkzNDgzQzMwLjQ1MzUgMC4yMDI5NTkgMjkuODIwMyAwLjIwMjk1OSAyOS40Mjk4IDAuNTkzNDgzQzI5LjAzOTMgMC45ODQwMDcgMjkuMDM5MyAxLjYxNzE3IDI5LjQyOTggMi4wMDc3TDM1LjA4NjYgNy42NjQ1NUwyOS40Mjk4IDEzLjMyMTRDMjkuMDM5MyAxMy43MTE5IDI5LjAzOTMgMTQuMzQ1MSAyOS40Mjk4IDE0LjczNTZDMjkuODIwMyAxNS4xMjYxIDMwLjQ1MzUgMTUuMTI2MSAzMC44NDQgMTQuNzM1NkwzNy4yMDggOC4zNzE2NlpNMC43ODEyNSA4LjY2NDU1SDM2LjUwMDhWNi42NjQ1NUgwLjc4MTI1VjguNjY0NTVaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K'); }
                &:hover, &:active {
                    color: $font-color-white;
                    &::after {
                        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAzOCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2Ljc4MjIgOC4yMDcxMUMzNy4xNzI3IDcuODE2NTggMzcuMTcyNyA3LjE4MzQyIDM2Ljc4MjIgNi43OTI4OUwzMC40MTgyIDAuNDI4OTMyQzMwLjAyNzcgMC4wMzg0MDc4IDI5LjM5NDUgMC4wMzg0MDc4IDI5LjAwNCAwLjQyODkzMkMyOC42MTM1IDAuODE5NDU3IDI4LjYxMzUgMS40NTI2MiAyOS4wMDQgMS44NDMxNUwzNC42NjA5IDcuNUwyOS4wMDQgMTMuMTU2OUMyOC42MTM1IDEzLjU0NzQgMjguNjEzNSAxNC4xODA1IDI5LjAwNCAxNC41NzExQzI5LjM5NDUgMTQuOTYxNiAzMC4wMjc3IDE0Ljk2MTYgMzAuNDE4MiAxNC41NzExTDM2Ljc4MjIgOC4yMDcxMVpNMC4zNTU0NjkgOC41SDM2LjA3NTFWNi41SDAuMzU1NDY5VjguNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo='); } } }
            .btn__show-hide {
                &::after {
                    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAzMSAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNSAxTDE1LjUgMTVMMjkuNSAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg=='); }
                &:hover, &:active {
                    &::after {
                        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAzMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMS40Mzg0OEwxNSAxNS40Mzg1TDI5IDEuNDM4NDgiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K'); } } } }

        // [
        //     'aqua-blue', 'purpure', 'orange-dirty', 'dark-blue', 'green-natural', 'green', 'yellow',
        //     'yellow-grey', 'orange', 'green-lighter', 'orange-bright'
        // ]

        $default__breakpoints: ((aqua-blue, $primary-color-block-aqua-blue, #247d83, #358e94, #247d83, #0b1d1f), (purpure, $primary-color-block-purpure, #562442, #4E0C33, #75325A, #230F1B), (orange-dirty, $primary-color-block-orange-dirty, #ce5d2c, #d45f2c, #ce5d2c, #251108), (dark-blue, $primary-color-block-dark-blue, #28425e, #223f5e, #28425e, #0c141d), (green-natural, $primary-color-block-green-natural, #3a8809, #397c0f, #3a8809, #0b1803), (green, $primary-color-block-green, #3a8809, #397c0f, #3a8809, #0b1803), (yellow, $primary-color-block-yellow, #b19519, #8a7517, #b19519, #1b1704), (yellow-grey, $primary-color-block-yellow-grey, #b19519, #8a7517, #b19519, #1b1704), (orange, $primary-color-block-orange, #ce5d2c, #d45f2c, #ce5d2c, #251108), (green-lighter, $primary-color-block-green-lighter, #3a8809, #397c0f, #3a8809, #0b1803), (orange-bright, $primary-color-block-orange-bright, #d14512, #9e350e, #d14512, #1b0903));

        @each $colorName, $mainColor, $lighterColor, $darkerColor, $lighterButtonRedColor, $textColor in $default__breakpoints {
            &.slide-block_color_light_#{$colorName}-content {
                .block-header {
                    color: #{$mainColor};
                    .text {
                        color: #{$lighterColor}; } }
                .btn.btn__dark-light {
                    border-color: #{$darkerColor};
                    color: #{$lighterColor};
                    &:hover, &:active {
                        background: #{$darkerColor}; } }
                .profi-cards .profi-cards__item .item-container .content {
                    background: #{$mainColor}; }
                .reveiws .reveiws__card::after {
                    background: #{$mainColor}; }
                .form-profi {
                    input, textarea {
                        border-color: #{$lighterColor};
                        color: #{$mainColor};
                        &::placeholder {
                            color: #{$mainColor}; } }
                    .btn__red {
                        border-color: #{$lighterColor};
                        background: #{$lighterColor};
                        &:hover {
                            border-color: #{$lighterButtonRedColor};
                            background: #{$lighterButtonRedColor}; }
                        &:active {
                            border-color: #{$lighterButtonRedColor};
                            background: #{$lighterButtonRedColor}; } }
                    p.policy, p.policy a {
                        // color: rgba(138, 58, 106, 0.5)
                        color: #{$lighterButtonRedColor}; }
                    .custom-input {
                        &__file-button {
                            color: #{$lighterColor};
                            svg {
                                path {
                                    fill: #{$mainColor}; } } } } }
                .purpure-content-color {
                    color: #{$mainColor}; }
                .text, .text p {
                    color: #{$textColor}; }
                .btn__show-hide {
                    border-color: #{$textColor};
                    &:hover, &:active {
                        border-color: #{$mainColor};
                        background-color: #{$mainColor}; } }
                .tel, .mail {
                    color: #{$mainColor}; }
                .address, .work-times {
                    color: #{$textColor}; }
                .questions-accordion {
                    .card {
                        &.active {
                            .card-header {
                                .btn-name {
                                    color: #{$lighterColor}; } } } } }
                .reviews .reviews__card::after {
                    background: #{$mainColor}; } } } }

    // @each $animal, $color, $cursor in (puma, black, default),
    //                             (sea-slug, blue, pointer),
    //                             (egret, white, move)
    //     .#{$animal}-icon
    //         background-image: url('/images/#{$animal}.png')
    //         border: 2px solid $color
    //         cursor: $cursor

    // Cuts
    &.slide-block_cat_bottom-left {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% (100% - $cut-value));
        @media (max-width: 575px) {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% (100% - $cut-value-mobile)); } }
    &.slide-block_cat_bottom-right {
        clip-path: polygon(0% 0%, 100% 0%, 100% (100% - $cut-value), 0% 100%);
        @media (max-width: 575px) {
            clip-path: polygon(0% 0%, 100% 0%, 100% (100% - $cut-value-mobile), 0% 100%); } }
    &.slide-block_cat_top-left {
        clip-path: polygon(0% 0%, 100% (0% + $cut-value), 100% 100%, 0% 100%);
        @media (max-width: 575px) {
            clip-path: polygon(0% 0%, 100% (0% + $cut-value-mobile), 100% 100%, 0% 100%); } }
    &.slide-block_cat_top-right {
        clip-path: polygon(0% (0% + $cut-value), 100% 0%, 100% 100%, 0% 100%);
        @media (max-width: 575px) {
            clip-path: polygon(0% (0% + $cut-value-mobile), 100% 0%, 100% 100%, 0% 100%); } }
    &.slide-block_cat_bottom-left.slide-block_cat_top-left {
        clip-path: polygon(0% 0%, 100% (0% + $cut-value), 100% 100%, 0% (100% - $cut-value));
        @media (max-width: 575px) {
            clip-path: polygon(0% 0%, 100% (0% + $cut-value-mobile), 100% 100%, 0% (100% - $cut-value-mobile)); } }
    &.slide-block_cat_bottom-left.slide-block_cat_top-right {
        clip-path: polygon(0% (0% + $cut-value), 100% 0%, 100% 100%, 0% (100% - $cut-value));
        @media (max-width: 575px) {
            clip-path: polygon(0% (0% + $cut-value-mobile), 100% 0%, 100% 100%, 0% (100% - $cut-value-mobile)); } }
    &.slide-block_cat_bottom-right.slide-block_cat_top-left {
        clip-path: polygon(0% 0%, 100% (0% + $cut-value), 100% (100% - $cut-value), 0% 100%);
        @media (max-width: 575px) {
            clip-path: polygon(0% 0%, 100% (0% + $cut-value-mobile), 100% (100% - $cut-value-mobile), 0% 100%); } }
    &.slide-block_cat_bottom-right.slide-block_cat_top-right {
        clip-path: polygon(0% (0% + $cut-value), 100% 0%, 100% (100% - $cut-value), 0% 100%);
        @media (max-width: 575px) {
            clip-path: polygon(0% (0% + $cut-value-mobile), 100% 0%, 100% (100% - $cut-value-mobile), 0% 100%); } }

    // Reviews text color white to dark bg
    &.slide-block_color_dark-blue {
        .reviews-page {
            .project-name, .project-name a {
                color: $font-color-white; }
            .text, .company {
                color: $font-color-white; }
            .between {
                .name, .date {
                    color: $font-color-white; } } } }

    // Paddings
    // Index direction's blocks
    &.slide-block__paddings_type_centered-direction {
        .slide-block__content {
            padding-top: 190px;
            padding-bottom: 245px;
            @media (max-width: 425px) {
                padding-top: 130px;
                padding-bottom: 165px; } } }
    // Blocks with profi-cards with long names: news and articles. Blocks with directions colloms
    &.slide-block__paddings_type_centered-profi-news {
        .slide-block__content {
            padding-top: 140px;
            padding-bottom: 215px;
            @media (max-width: 425px) {
                padding-top: 90px;
                padding-bottom: 140px; } } }
    // Seo-text blocks
    &.slide-block__paddings_type_centered-seo-text {
        .slide-block__content {
            padding-top: 140px;
            padding-bottom: 280px;
            @media (max-width: 425px) {
                padding-top: 90px;
                padding-bottom: 190px; } } }
    // Contacts
    &.slide-block__paddings_type_centered-contacts {
        .slide-block__content {
            padding-top: 200px;
            padding-bottom: 270px;
            @media (max-width: 425px) {
                padding-top: 140px;
                padding-bottom: 180px; } } }
    // Directions blocks...
    // First block on direction page
    &.slide-block__paddings_type_first-on-direction {
        .slide-block__content {
            padding-top: 80px;
            padding-bottom: 230px;
            @media (max-width: 425px) {
                padding-top: 55px;
                padding-bottom: 150px; } } }
    &.slide-block__paddings_type_120-230-on-direction {
        .slide-block__content {
            padding-top: 110px;
            padding-bottom: 230px;
            @media (max-width: 425px) {
                padding-top: 70px;
                padding-bottom: 150px; } } }
    &.slide-block__paddings_type_250-230-form-on-direction {
        .slide-block__content {
            padding-top: 240px;
            padding-bottom: 180px;
            @media (max-width: 425px) {
                padding-top: 160px;
                padding-bottom: 120px; } } }
    &.slide-block__paddings_type_70-230-on-direction {
        .slide-block__content {
            padding-top: 60px;
            padding-bottom: 230px;
            @media (max-width: 425px) {
                padding-top: 40px;
                padding-bottom: 150px; } } }
    &.slide-block__paddings_type_centered-form-280 {
        .slide-block__content {
            padding-top: 270px;
            padding-bottom: 260px;
            @media (max-width: 425px) {
                padding-top: 180px;
                padding-bottom: 170px; } } }
    &.slide-block__paddings_type_q-and-a {
        .slide-block__content {
            padding-top: 110px;
            padding-bottom: 230px;
            @media (max-width: 991px) {
                padding-top: 140px; }
            @media (max-width: 425px) {
                padding-top: 60px;
                padding-bottom: 150px; } } }
    &.slide-block__paddings_type_cms {
        .slide-block__content {
            padding-top: 130px;
            padding-bottom: 180px;
            @media (max-width: 425px) {
                padding-top: 65px;
                padding-bottom: 115px; } } }
    &.slide-block__paddings_type_q-and-a-top-less {
        .slide-block__content {
            padding-top: 60px;
            padding-bottom: 230px;
            @media (max-width: 425px) {
                padding-top: 40px;
                padding-bottom: 150px; } } }
    &.slide-block__paddings_type_review {
        .slide-block__content {
            padding-top: 160px;
            padding-bottom: 165px;
            @media (max-width: 425px) {
                padding-top: 100px;
                padding-bottom: 105px; } } }
    &.slide-block__paddings_type_reviews-form {
        .slide-block__content {
            padding-top: 110px;
            padding-bottom: 200px;
            @media (max-width: 425px) {
                padding-top: 70px;
                padding-bottom: 140px; } } }
    &.slide-block__paddings_type_quize {
        .slide-block__content {
            padding-top: 80px;
            padding-bottom: 80;
            @media (max-width: 425px) {
                padding-top: 40px;
                padding-bottom: 40px; } } }

    // Custom addings
    &.footer {
        margin-bottom: 0;
        align-items: flex-start;
        .slide-block__content {
            padding-top: 190px;
            // padding-bottom: 130px
            @media (max-width: 425px) {
                padding-top: 130px; } }
        &.slide-block_cat_top-left {
            clip-path: polygon(0% 0%, 100% (0% + $cut-value - 1.5%), 100% 100%, 0% 100%);
            @media (max-width: 575px) {
                clip-path: polygon(0% 0%, 100% (0% + $cut-value-mobile - 1.5%), 100% 100%, 0% 100%); } }
        &.slide-block_cat_top-right {
            clip-path: polygon(0% (0% + $cut-value - 1.5%), 100% 0%, 100% 100%, 0% 100%);
            @media (max-width: 575px) {
                clip-path: polygon(0% (0% + $cut-value-mobile - 1.5%), 100% 0%, 100% 100%, 0% 100%); } } } }


